import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';

import ElementUI from 'element-ui';
// import Recorder from 'recorder-js';
import 'element-ui/lib/theme-chalk/index.css'; // 引入Element UI样式
import VantGreen from 'vant-green';
import 'vant-green/lib/index.css';



import Swiper from 'swiper/swiper-bundle.min.js';

import 'swiper/swiper-bundle.min.css';

// import { Swipe, SwipeItem } from 'vant-green';



import axios from "axios";
//自适应不同分辨率
import 'lib-flexible-computer'

Vue.prototype.axios = axios;
Vue.config.productionTip = false
Vue.use(echarts)
Vue.use(ElementUI);
Vue.use(VantGreen);
// Vue.use(Recorder);


// Vue.component('my-component',{
//   template: myTemplate,
// })
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
