<template>
  <div id="app">
    <router-view></router-view>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/> -->
  </div>
</template>

<style>
@import './static/style.css';
</style>
<script>
export default {
  name: 'App',

  created() {
    // setTimeout(()=> {
    //   if(localStorage.getItem('token') == '' || localStorage.getItem('token') == undefined) {
    //     this.$router.push('/login')
    //   }else {
    //     this.$router.push('/Home')
    //   }
    // },2000)
  },
  mounted() {
    this.lockLandscapeOrientation();
  },
  methods: {
    lockLandscapeOrientation() {
      // 锁定横屏正向
      screen.orientation.lock('landscape-primary').catch(e => {
        console.error('无法锁定屏幕方向:', e);
      });
    }
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
