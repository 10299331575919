<template>
  <div class="zs-box">
    <div style="text-align:left;position: absolute;top: 0;" >
      <img @click="zsback" class="zs-back" src="../assets/xiaobangyy/masonry/back.png" alt="">
    </div>
    <div class="dhlist">
      <img class="zsdhbd" src="../assets/xiaobangyy/masonry/zsdh.png" alt="">
      <div class="embedded">
        <div class="bdcontent">
          <div class="ranking-list">
            <div class="jjbox2">
             
              <div class="qmjj">{{ twoaward }}</div>
            </div>
            <div class="jjbox1">
            
              <div class="qmjj">{{ oneaward }}</div>
            </div>
            <div class="jjbox3">
   
              <div class="qmjj">{{ threeaward }}</div>
            </div>
          </div>

          <div class="syranking" v-for="(item,index) in hhlist" :key="index">
            <div class="yqasc">{{ item.ph }}</div>
            <div class="vaounf"><span>{{ item.name }}</span><span>{{ item.description }}</span><span>{{ item.xjewel - WJewel <=0 ? '已完成': '还需'+item.xjewel - WJewel+'砖石'}}</span></div>
          </div>
          <!-- <div class="syranking">
            <div class="yqasc">五</div>
            <div class="vaounf"><span>四等奖</span><span>现金300元</span><span>还需250砖石</span></div>
          </div>
          <div class="syranking">
            <div class="yqasc">六</div>
            <div class="vaounf"><span>四等奖</span><span>现金300元</span><span>还需250砖石</span></div>
          </div>
          <div class="syranking">
            <div class="yqasc">七</div>
            <div class="vaounf"><span>四等奖</span><span>现金300元</span><span>还需250砖石</span></div>
          </div> -->

        </div>

        
      </div>

    </div>
  </div>
</template>
<script>
import * as uploadFiles from "@/api/uploadFiles";
export default {
  data() {
    return {
      zsList:[],
      twoaward:'',
      oneaward:'',
      threeaward:'',
      hhlist:[],
      WJewel:0

    }
  },
  created() {
    this.getList()
    this.myjewel()

  },
  methods:{
    zsback() {
      this.$router.back();
    },
    getList() {
      uploadFiles.jewelmallLsit().then((res)=> {
     
        if(res.data.code == 200) {
          this.oneaward = res.data.datas[0].description
          this.twoaward = res.data.datas[1].description
          this.threeaward = res.data.datas[2].description
          let arr = JSON.parse(JSON.stringify(res.data.datas))
          arr.map((item,index) => {
            item.xjewel = Number(item.xjewel)
            this.$set(item, 'ph', item.name.substr(0,1));
          })
       
          this.hhlist = arr.slice(3, arr.length)
          
          console.log(this.hhlist);
        }
      })

    },
    myjewel() {
      uploadFiles.jewelMy().then((res)=> {
        if(res.data.code == 200) {
         console.log(res.data.datas);
          this.WJewel = res.data.datas.jewel
          console.log(this.WJewel);
        }
      })
    }
    
  }
}


</script>
<style>
.zs-box {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/xiaobangyy/masonry/zsbj.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.zs-back {
  width: 70px;
  height: 70px;
  margin-top: 30px;
  margin-left: 30px;
}
.dhlist {
  display: flex;
  justify-content: center;
  width: 500px;
  height: 500px;
  background: #F0F0F0;
  margin: auto;
  border-radius: 30px;
  padding-top: 30px;
  position: relative;
  
}
.zsdhbd {
  position: absolute;
  top: 0;
  margin-top: -30px;
}
.embedded {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 30px;

}
.bdcontent {
  width: 90%;
  height: 90%;
  background: red;
  border-radius: 30px;
  padding: 0 30px;
}
.ranking-list {
  width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}
.syranking {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin: 10px 0;
}
.yqasc {
  width: 30px;
  height: 30px;
  background: #FF5765;
 
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #fff;
  font-weight: 700;

}
.vaounf {
  flex: 1;
  height: 100%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #DB1E31;
  color: #FCEAEC;
  border-radius: 15px;
}
.qmjj {
  margin-top: 77px;
  font-size: 10px;
}
.jjbox2 {
  width: 125px;
  height: 150px;
  background-image: url(../assets/xiaobangyy/masonry/secondprize.png);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.jjbox1 {
  width: 125px;
  height: 150px;
  background-image: url(../assets/xiaobangyy/masonry/firstprize.png);
  display: flex;
  justify-content: center;
  align-items: center;
}
.jjbox3 {
  width: 125px;
  height: 150px;
  background-image: url(../assets/xiaobangyy/masonry/thirdprize.png);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
