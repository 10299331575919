<template>
    <div class="teacher-box">
        <div style="text-align:left;position: absolute;top: 0;" >
            <img @click="zsback" class="zs-back" src="../assets/xiaobangyy/masonry/back.png" alt="">
        </div>
        <div class="xztitle">选择我的AI老师</div>
        <div class="aibox" >
            <div class="trbox" v-for="(item) in teacherList" :key="item.id" @click="setercli(item.id)">   
                <img class="trimg" :class="  qhid == item.id ? '_thistr' : ''" :src="item.img" alt="">
                <div class="trname">{{ item.name }}</div>
            </div>
        </div>
        <img class="ksabtn" src="../assets/xiaobangyy/btn.png" alt="" @click="btnOk">
       
    </div>
</template>
<script>
import * as uploadFiles from "@/api/uploadFiles";
export default {
    created() {
        this.getList()
        this.getmyaiteacher()

    },
    data() {
        return{
            teacherList:[],
            qhid:''
        }

    },
    methods:{
        zsback() {
             this.$router.back();
        },
        getList() {
            uploadFiles.aiteacherList().then((res)=> {
     
                if(res.data.code == 200) {
                    console.log(res);
                    this.teacherList = res.data.datas
                }
            })
        },
        setercli(e) {
            console.log(e);
            this.qhid = e
        },
        getmyaiteacher() {
            uploadFiles.aiteacherGet().then((res)=> {
     
                if(res.data.code == 200) {
                    console.log(res);   
                    this.qhid = res.data.datas.id
                    
                }
            })
        },
        btnOk() {
            console.log(this.qhid);
            uploadFiles.aiteacherSet({'ai_teacher_id':this.qhid}).then((res)=> {
     
                if(res.data.code == 200) {
                    console.log(res);   
                    this.$message({
                        message: '切换成功！',
                        type: "success",
                    });
                    this.getmyaiteacher()
                    this.zsback()
                    
                }
            })

        }
    }
}
</script>
<style>
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.teacher-box {
    width: 100%;
    height: 100vh;
    background-image: url(../assets/xiaobangyy/wdlsbj.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.xztitle{
    padding-top: 80px;
    color: #fff;
    font-size: 20px;
}
.aibox {
    width: 520px;
    height: 360px;
    background: #fff;
    border-radius: 20px;
    margin:20px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.ksabtn {
    margin-top: 20px;
}
.trbox {
    width: 120px;
    height: 160px;
    margin: 5px;
}
.trimg {
    width: 100%;
    height: 90%;
    border-radius: 5px;

}
._thistr {
    border: 2px solid red;
}
.trname {
    font-size: 16px;
    font-weight: 700;
}
</style>