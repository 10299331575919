<template>
    <div class="audio-player">
      <div class="audio-visualizer">
        <div class="bar" v-for="n in 15" :key="n"></div>
      </div>
      <div class="langdunr">请大声朗读出内容</div>
     
    </div>
  </template>
  
  <script>
  export default {
    name:'recorddh',
    data() {
      return {
        isPlaying: false,
        progress: 0,
      };
    },
    methods: {

    },
  };
  </script>
  
  <style scoped>
  .langdunr {
    margin-top: 15px;
    font-size: 21px;
    color: #ccc;
  }
  .audio-player {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .audio-visualizer {
   
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 60px;
 
  }
  
  .bar {
    width: 10px;
    height: 50px;
    background-color: #42b983;
    margin: 0 10px;
    animation: sound 0ms -800ms linear infinite alternate;
  }
  
  @keyframes sound {
    0% {
      opacity: 0.35;
      height: 6px;
    }
    100% {
      opacity: 1;
      height: 50px;
    }
  }
  
  .bar:nth-child(1)  { animation-duration: 474ms; }
  .bar:nth-child(2)  { animation-duration: 433ms; }
  .bar:nth-child(3)  { animation-duration: 407ms; }
  .bar:nth-child(4)  { animation-duration: 458ms; }
  .bar:nth-child(5)  { animation-duration: 400ms; }
  .bar:nth-child(6)  { animation-duration: 427ms; }
  .bar:nth-child(7)  { animation-duration: 441ms; }
  .bar:nth-child(8)  { animation-duration: 419ms; }
  .bar:nth-child(9)  { animation-duration: 487ms; }
  .bar:nth-child(10) { animation-duration: 442ms; }
  .bar:nth-child(11) { animation-duration: 474ms; }
  .bar:nth-child(12) { animation-duration: 441ms; }
  .bar:nth-child(13) { animation-duration: 407ms; }
  .bar:nth-child(13) { animation-duration: 442ms; }
  .bar:nth-child(14) { animation-duration: 433ms; }
  .bar:nth-child(15) { animation-duration: 469ms; }
  
  .playing .bar {
    animation-play-state: running;
  }
  
  .playing .bar:nth-child(1)  { animation-duration: 474ms; }
  .playing .bar:nth-child(2)  { animation-duration: 433ms; }
  .playing .bar:nth-child(3)  { animation-duration: 407ms; }
  .playing .bar:nth-child(4)  { animation-duration: 458ms; }
  .playing .bar:nth-child(5)  { animation-duration: 400ms; }
  .playing .bar:nth-child(6)  { animation-duration: 427ms; }
  .playing .bar:nth-child(7)  { animation-duration: 441ms; }
  .playing .bar:nth-child(8)  { animation-duration: 419ms; }
  .playing .bar:nth-child(9)  { animation-duration: 487ms; }
  .playing .bar:nth-child(10) { animation-duration: 442ms; }
  .playing .bar:nth-child(11) { animation-duration: 474ms; }
  .playing .bar:nth-child(12) { animation-duration: 441ms; }
  .playing .bar:nth-child(13) { animation-duration: 407ms; }
  .playing .bar:nth-child(14) { animation-duration: 433ms; }
  .playing .bar:nth-child(15) { animation-duration: 433ms; }
  
  </style>