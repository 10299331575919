<template>
  <div class="kcb-box">
    <div class="cxjasg" v-if="jdskdl">
      <div class="kcb-header">
        <div style="display: flex; align-items: center">
          <img class="kcb-back" @click="backgo" src="../assets/xiaobangyy/back.png" alt="" />
        </div>
        <div>同步课堂</div>
        <div></div>
      </div>
      <div class="kcb-list">
        <el-select
          @change="njchange"
          v-model="user.nj"
          placeholder="请选择年级"
        >
          <el-option
            v-for="item in njoptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          style="margin-left: 20px"
          v-model="user.xk"
          placeholder="请选择学科"
        >
          <el-option
            v-for="item in xkoptions"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="kvb-body">
        <div class="kcb-headgma">
          课程目录 <span class="gsjhwrf">(共{{ hsafg }}课)</span>
        </div>

        <div class="book-box" v-if="bookjagn">
          <div
            class="book-gsjdfh"
            v-for="(item, index) in bookList"
            :key="index"
            @click="openBook(item)"
          >
            <img class="bookimg" :src="item.thumb" alt="" />
            <div class="foaehfs">{{ item.name }}</div>
          </div>
        </div>
        <div class="zwkc-wz" v-else>暂无课程</div>
      </div>
    </div>

    <div class="cxjasg2" v-else>
        <div class="xzkbbod">
            
            <div class="kbnr-ys"> <div class="dsdhf" @click="fhback">返回上一项</div> <div >请选择课程</div><div></div></div>

            <div class="jdsfdzs">

                <div
                    class="book-gsjdfh"
                    v-for="(item, index) in schedule"
                    :key="index"
                    @click="tothxgasg(item)"
                >
                    <img class="bookimg" :src="item.thumb" alt="" />
                    <div class="foaehfs">{{ item.name }}</div>
                </div>
            </div>
        </div>


    </div>
  </div>
</template>
<script>
import * as uploadFiles from "@/api/uploadFiles";
export default {
  mounted() {
    this.getxklist();
    this.geiuserList();

    // this.getpress()
  },
  data() {
    return {
      user: {
        xk: "英语",
        nj: "全部",
      },
      xkoptions: [],
      njoptions: [],
      hsafg: "*",
      bookList: [],
      bookjagn: true,
      jdskdl:true,
      schedule:[]
    };
  },
  methods: {
    backgo() {
        this.$router.back();
    },
    // getpress() {
    //     uploadFiles.press().then((res) => {
    //         if (res.data.code == 200) {
    //             console.log(res,'999');

    //         }
    //     });
    // },
    // 年级
    getxklist() {
      uploadFiles.grade().then((res) => {
        if (res.data.code == 200) {
          console.log(res);
          this.njoptions = res.data.datas;
          let arr = {
            name: "全部",
            id: "8888888",
          };
          this.njoptions.unshift(arr);
        }
      });
      // 学科
      uploadFiles.subject().then((res) => {
        if (res.data.code == 200) {
          console.log(res);
          // this.xkoptions = res.data.datas
          this.xkoptions = [
            {
              name: "英语",
              id: 0,
            },
          ];
        }
      });
    },
    geiuserList() {
      uploadFiles.quicksearch({ gid: 0, sid: 0, pid: 0 }).then((res) => {
        if (res.data.code == 200) {
          console.log(res, "000");
          this.hsafg = res.data.datas.length;
          this.bookList = res.data.datas;
        }
      });
    },
    njchange(e) {
      this.updatetextbook(e);
    },
    updatetextbook(e) {
      if (e == "8888888") {
        e = 0;
      }
      uploadFiles.quicksearch({ gid: e, sid: 0, pid: 0 }).then((res) => {
        if (res.data.code == 200) {
          console.log(res);
          if (res.data.datas == undefined) {
            this.hsafg = "0";
            this.bookList = [];
            this.bookjagn = false;
            return;
          }
          this.bookjagn = true;
          this.bookList = res.data.datas;

          this.hsafg = res.data.datas.length;
        }
      });
    },
    openBook(e) {
      console.log(e);
      uploadFiles.subjectcontent({ cid: e.id }).then((res) => {
        if (res.data.code == 200) {
          console.log(res, "666666");
          this.jdskdl = false
          this.schedule = res.data.datas
          console.log(this.schedule);
          
        }
      });
    },
    fhback() {
        this.jdskdl = true
        this.schedule = []

    },
    tothxgasg(e) {
        console.log(e);
         this.$router.push({ name: 'OpenClass', params: { userId: e.id } });
      
        this.jdskdl = true
        this.schedule = []

    }
  },
};
</script>
<style scoped>
.kcb-box {
  width: 100%;
  height: 100vh;
  padding: 0 40px;
  background-image: url("../assets/xiaobangyy/wdlsbj.jpg");
  background-size: 100%;
}
.cxjasg {
    width: 100%;
  height: 100vh;
}
.cxjasg2 {
    width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.xzkbbod {
    
    width: 100%;
    height: 85%;
    background: #fff;
    overflow: auto;

}
.kbnr-ys {
    width: 100%;
    height: 70px;
    line-height: 70px;
    font-size: 18px;
    text-align: center;
  
    border-bottom: 1px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dsdhf {
    font-size: 18px;
    color: #409EFF;
    margin-left: 15px;
}
.kcb-header {
  width: 100%;
  color: #fff;
  height: 8%;
  line-height: 8%;
  font-size: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kcb-back {
  width: 50px;
}
.kvb-body {
  width: 100%;
  height: calc(100% - 8% - 140px - 4%);
  background-color: #fff;
  overflow-y: auto;
}
.kcb-list {
  width: 100%;
  height: 100px;

  display: flex;
  align-items: center;
}
/deep/.el-input__inner {
  margin-right: 20px !important;
  height: 50px;
  line-height: 50px;
  -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
 
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
}
.kcb-headgma {
  width: 100%;
  height: 70px;
  line-height: 70px;
  border-bottom: 1px dashed #ccc;
  font-size: 20px;
  text-align: left;

  padding: 0 30px;
}
.gsjhwrf {
  font-size: 16px;
  color: #ccc;
}
.book-box {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}
.bookimg {
  width: 100%;


}
.foaehfs {
  position: absolute;
  height: 40px;
    line-height: 40px;
    background: #75a9de;
    width: 100%;
    color: #FFF;
    font-size: 18px;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
    bottom: 0;
}
 .book-gsjdfh {
 position: relative;
 width: 200px;

 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;

 box-shadow: 3px 3px 3px  0 rgba(148, 141 ,141, 50%);

 margin: 10px 18px;

}
.zwkc-wz {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 22px;
  color: #ccc;
}
.jdsfdzs {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  
}
</style>
