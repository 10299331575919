import axios from 'axios'
// import { Message } from 'element-ui'
//创建axios实列


const service = axios.create({
    baseURL: 'https://yyjx.iwangbang.com/',
    timeout:20000
})

service.defaults.headers['Content-Type'] =
  'application/x-www-form-urlencoded';




 

  service.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if(token) {
      config.headers['token'] = token; 
    }
    
     
 
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  service.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });












// service.interceptors.request.use(config => {
//     let pathname = location.pathname
//     if(localStorage.getItem('token')) {
//         if(pathname != '/' && pathname != '/login') {
//             config.headers.common['token'] = localStorage.getItem('token')
//         }
//         console.log('token');
//     }
//     return config
// },error => {
//     return Promise.reject(error)
// })

// service.interceptors.request.use(config => {
//     const token = localStorage.getItem('token');
// 		token && (config.headers.Authorization = token);
// 		return config
    
// },err => {
//     return Promise.reject(err)
// })

// service.interceptors.response.use(response =>{
//    const {success,message,data} = response.data
//    if(success) {
//        return data
//    } {
//     Message.error(message)
//     return Promise.reject(new Error(message))
//    }
// },error => {
//     Message.error(error.message)
//     return Promise.reject(error)
// })

export default service
