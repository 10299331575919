<template>
  <div class="home-box">
    <!-- <div class="home-header">
      <div class="home-header-left">
        <div class="home-header-head">
          <img class="userheader" :src="userimg" alt="" />
        </div>
        <div class="userName">{{ username }}</div>
        <div class="home-integral" @click="openmasonry()">
          <img src="../assets/xiaobangyy/masonry.png" alt="" />
          <div class="grade">{{ usergrade }}</div>
        </div>
      </div>
      <div class="home-header-right">
        <img
          class="tracking"
          @click="learnTrack"
          src="../assets/xiaobangyy/track.png"
          alt=""
        />
        <img
          class="myteacher"
          @click="openmytr()"
          src="../assets/xiaobangyy/teacher.png"
          alt=""
        />
      </div>
    </div> -->
    <div class="home-header">
      <div class="fasfxaf">
        <img
          class="tracking"
          @click="learnTrack"
          src="../assets/xiaobangyy/track.png"
          alt=""
        />
        <div class="fajsfh">学习轨迹</div>
      </div>
        <div style="display: flex;align-items: center;">
          <div class="home-header-head">
          <img class="userheader" :src="userimg" alt="" />
        </div>
        <div class="userName">{{ username }}</div>
        </div>
        <div class="home-integral" @click="openmasonry()">
          <img src="../assets/xiaobangyy/masonry.png" alt="" />
          <div class="grade">{{ usergrade }}</div>
        </div>
     
    
        <div class="fasfxaf">
          <img
            class="myteacher"
            @click="openmytr()"
            src="../assets/xiaobangyy/teacher.png"
            alt=""
          />
          <div class="fajsfh">我的老师</div>
        </div>
    </div>

    <div class="home-body" style="display: flex;flex-direction: column;">
      <div class="alushf">
        <!-- <div @click="reviewkc" style="display: flex;justify-content: center;align-items: center;" >
          <img class="btnimg" src="../assets/xiaobangyy/czbtn/qx.png" alt="">
          <div class="btntext">复习上一课</div>
        
        </div> -->
        <button @click="reviewkc" class="gascbtn review-lessons">专属课</button>
        
      </div>
      <div class="alushf">
        <!-- <div style="display: flex;justify-content: center;align-items: center;" @click="ljstudy()">
          <img class="btnimg" src="../assets/xiaobangyy/czbtn/qr.png" alt="">
          <div class="btntext">立即学习</div>
        </div> -->
        <button class="gascbtn xxxkc" @click="learncourse">同步课</button>
      </div>
      <!-- <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in learnlist" :key="item.id" @click="opestudy(item.course_id)">
            <div class="ementkj">{{ item.name }}</div>
            <img class="keimg" :src="item.thumb" alt="" />
            <div class="status-box" v-if="item.study_plan == 0">
              <div class="wcstatus-1"></div>
              <div class="wcwire1"></div>
              <div class="status-2"></div>
              <div class="wire"></div>
              <div class="status-3"></div>
            </div>
            <div class="status-box" v-else-if="item.study_plan == 1">
              <div class="wcstatus-1"></div>
              <div class="wcwire1"></div>
              <div class="wcstatus-2"></div>
              <div class="wire"></div>
              <div class="status-3"></div>
            </div>
            <div class="status-box" v-else-if="item.study_plan == 2">
              <div class="wcstatus-1"></div>
              <div class="wcwire1"></div>
              <div class="wcstatus-2"></div>
              <div class="wcwire1"></div>
              <div class="wcstatus-3"></div>
            </div>
            <div class="status-box" v-else>
              <div class="status-1"></div>
              <div class="wire"></div>
              <div class="status-2"></div>
              <div class="wire"></div>
              <div class="status-3"></div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div> -->
      </div>


    </div>

    <!-- <div class="home-foot">
      <div>
        <img class="footimg" src="../assets/xiaobangyy/xxzx.png" alt="" />
        <img
          @click="openClass"
          class="footimg"
          src="../assets/xiaobangyy/gkk.png"
          alt=""
        />
      </div>
    </div> -->

    <!-- <newsHint class="aksbfxx"></newsHint> -->
  </div>
</template>

<script>
// @ is an alias to /src
import newsHint from "../components/popup.vue";
import * as uploadFiles from "@/api/uploadFiles";
import Swiper from "swiper";
export default {
  name: "Home",
  components: {
    newsHint,
  },

  created() {
    
  },
  activated() {

  },

  mounted() {
    this.getUserInfo();
    this.getmasonry();
    this.getCourses();
    
  },
  computed: {},
  data() {
    return {
      wswiper: "",
      userimg: "",
      centerDialogVisible: false,
      innerVisible: false,
      username: "***",
      usergrade: "***",
      wwclist: true,
      learnlist: [
        // {
        //   name:'任务完成',
        //   id:0,
        //   url: require('../assets/xiaobangyy/wc.png')
        // },
        // {
        //   name:'没有任务',
        //   id:0,
        //   url: require('../assets/xiaobangyy/myrw.png')
        // }
      ],
    };
  },
  methods: {
    doSwiper() {
      this.wswiper = new Swiper(".mySwiper", {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
      console.log(this.wswiper);
    },
    opestudy(e) {
      console.log(e);
      this.$router.push({ path: '/teaching', query: { course_id: e }})
      // this.$router.push("/teaching");
    },
    learnTrack() {
      this.$router.push("/LearningTrajectory");
    },
    openmasonry() {
      this.$router.push("/masonry");
    },
    openmytr() {
      this.$router.push("/Myteacher");
    },
    openClass() {
      this.$router.push("/openClass");
    },
    openbox() {
      this.centerDialogVisible = true;
      this.innerVisible = true;
    },
    getUserInfo() {
      uploadFiles.getUserInfo().then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.username = res.data.datas.nickname;
          this.userimg = res.data.datas.pic;
        }
      });
    },
    getmasonry() {
      uploadFiles.jewelMy().then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.usergrade = res.data.datas.jewel;
        }
      });
    },
    getCourses() {
      uploadFiles.studycentreCourses().then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.learnlist = res.data.datas;

          this.$nextTick(() => {
            this.doSwiper();
          });
        }
      });
    },
    carchange(index) {
      console.log(index);
    },
    ljstudy() {
      this.$router.push("/OpenClass");
    },
    reviewkc() {
      this.$message({
              message: "当前没有复习的内容哦，请直接学习新课程！",
              type: "success",
            });
            return
      uploadFiles.laststudy().then((res) => {
        console.log(res);
        if(res.data.code == '200') {
            this.$router.push({ name: 'OpenClass', params: { userId: '复习上一节课内容' } });
        }else {
          this.$message({
              message: "当前没有复习的内容哦，请直接学习新课程！",
              type: "success",
            });
        }

      })
      // 
     

    },
    learncourse() {
      this.$router.push('/scheduleclass');

      // this.$router.push({ name: 'OpenClass', params: { userId: '学习新内容' } });
    }

  },
};
</script>
<style>
.home-box {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/xiaobangyy/loginbj.png);
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-header {
  width: 670px;
    padding: 0 50px;
    height: 130px;
    margin: 50px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.home-header-left {
  display: flex;
  align-items: center;
  padding: 20px 0 0 30px;
}
.home-header-head {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-right: 10px;
  background: red;
}
.userheader {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.userName {
  color: #ffffffe1;
  font-size: 20px;
}
.home-integral {
  display: flex;
  align-items: center;

  height: 45px;
  border-radius: 20px;
  background: #102236;
  
  padding: 0 10px;
}
.home-integral > img {
  width: 35px;
  height: 30px;
}
.grade {
  font-size: 20px;
  color: #fff;

  margin: 0 10px;
}
.home-header-right {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-right: 20px;
}
.tracking {
  width: 95px;
  height: 95px;
  
}
.myteacher {
  width: 95px;
  height: 95px;
}
.home-body {
  width: 90%;
  height: 55%;
  display: flex;
  padding: 0 100px;
  margin: auto;
}
.home-izjbv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-foot {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footimg {
  width: 170px;
  height: 150px;
  margin-top: -40px;
}
.learnbox {
  width: 290px;
  height: 90%;
  background: #fff;
  border-radius: 40px;
  overflow: hidden;
}
.ementkj {
  text-align: left;
  width: 120px;
  height: 40px;
  line-height: 40px;
  padding-left: 30px;
  font-size: 20px;
  background: red;
  color: #e9e9e9;
  border-bottom-right-radius: 30px;
  margin-bottom: 15px;
}
.accomplishbox {
  width: 320px;
  height: 90%;
  background: #fff;

  border: 8px solid red;
  border-radius: 40px;
}
.wcrwimg {
  margin-left: -15px;
  margin-top: 60px;
}
.asgxx {
  font-size: 24px;
  color: #36dc7f;
  font-weight: 700;
  margin-top: 10px;
}
.myrwx {
  font-size: 24px;
  color: #ffd858;
  font-weight: 700;
  margin-top: 10px;
}
.wcxx {
  font-size: 24px;
  margin-top: 50px;
}
.bcxx {
  font-size: 20px;
  margin-top: 20px;
}
.status-1 {
  width: 50px;
  height: 50px;
  background-image: url(../assets/xiaobangyy/state/icon.png);
  background-position: -94px 72px;
}
.wcstatus-1 {
  width: 50px;
  height: 50px;
  background-image: url(../assets/xiaobangyy/state/icon.png);
  background-position: -427px 72px;
}
.status-2 {
  width: 50px;
  height: 50px;
  background-image: url(../assets/xiaobangyy/state/icon.png);
  background-position: -194px 72px;
}
.wcstatus-2 {
  width: 50px;
  height: 50px;
  background-image: url(../assets/xiaobangyy/state/icon.png);
  background-position: -528px 72px;
}
.status-3 {
  width: 50px;
  height: 50px;
  background-image: url(../assets/xiaobangyy/state/icon.png);
  background-position: -626px 72px;
}
.wcstatus-3 {
  width: 50px;
  height: 50px;
  background-image: url(../assets/xiaobangyy/state/icon.png);
  background-position: -292px 72px;
}
.status-box {
  width: 75%;
  margin: auto;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wire {
  flex: 1;
  height: 2px;
  background: #ebe9e9;
}
.wcwire1 {
  flex: 1;
  height: 2px;
  background: #d0f8ff;
}
.kasdxx {
  position: absolute;
}

.outer-box {
  width: 500px;
  justify-self: 500px;
}
.aksbfxx {
  width: 100%;
  height: 100vh;
  position: absolute;
}

.keimg {
  width: 80%;
  height: 70%;
  border-radius: 40px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.van-swipe__track {
  width: 996px;
  height: 160px;
  transition-duration: 500ms;
  transform: translateX(0px);
  display: flex;
}
.swiper {
  width: 90%;
  height: 100%;
  display: flex;
  padding: 0 10px;
  margin: auto;
}
.swiper-slide {
  width: 31% !important;
  height: 100% !important;
  background: #fff;
  border-radius: 40px;
  overflow: hidden;
}
.alushf {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnimg {
  position: relative;
  width: 550px;
  height: 190px;
  
}
.btntext {
  position: absolute;
  font-size: 40px;
  color: #fff;
  font-weight: 700;

}
.gascbtn {
  width: 300px;
  height: 70px;
  margin: 30px 0 20px 0;
  border: 0;
  border-radius: 80px;
  font-size: 18px;
  color: #fff;
}
.review-lessons {

  background: #FF6B78;

  

}
.xxxkc {
  background: #1CADFA;
}







.fasfxaf {
  position: relative;
  display: flex;
  justify-content: center;
  width: 95px;
  height: 95px;
}
.fajsfh {
  position: absolute;
  width: 80%;
  
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  background: red;
  color: #fff;
  border-radius: 20px;
  bottom: 0;
  margin-bottom: 14px;
}
</style>
