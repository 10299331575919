<template>
  <div class="track-box" @touchstart="handleTouchStart" @touchmove="handleTouchMove" >
    <img @click="zsback" class="zs-back" src="../assets/xiaobangyy/masonry/back.png" alt="">

    
    <img class="xxgjbj"  src="../assets/xiaobangyy/xxgj/xxgj.jpg" alt="" />
    <div
      id="demo"
      ref="myDiv"
      style="width: 2600px; height: 70vh; position: absolute;padding-left: 100px;padding-top: 100px"
    >
    </div> 
  </div>
</template>
<script>
var echarts = require("echarts");
import * as uploadFiles from "@/api/uploadFiles";
export default {
  created() {
 
 this.getlearningpath()
},
  mounted() {

 


    
  },
  data() {
    return {
      startX: 0,
      startY: 0,
      list:[
            // {value: 820, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png',name:'Visit'},
            // {value: 932, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png',name:'Buy'},
            // {value: 901, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png',name:'2222322'},
            // {value: 934, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png',name:'444444'},
            // {value: 1010, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png',name:'55555555'},
            // {value: 953, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 910, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 984, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 846, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 950, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 829, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 800, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 999, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 800, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 850, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 1120, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 980, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 906, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 985, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 888, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 800, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 880, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 950, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 960, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 790, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 840, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 899, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 950, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 1020, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
            // {value: 1056, symbol: 'image://https://www.iwangbang.com/skin/zmtlogin/img/logo.png'},
          
        
      ],
      screenWidth: 0,
      screenHeight: 0,
      nodes: [
        {
          position: 10,
          top: 50,
          url: require("../assets/xiaobangyy/xxzx.png"),
        },
        {
          position: 30,
          top: 80,
          url: require("../assets/xiaobangyy/xxzx.png"),
        },
      ],
    };
  },
 


  methods: {
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      const currentX = event.touches[0].clientX;
      const currentY = event.touches[0].clientY;
      const distanceX = currentX - this.startX;
      const distanceY = currentY - this.startY;
 
      if (Math.abs(distanceX) < Math.abs(distanceY)) {
        // 当滑动距离Y大于滑动距离X时，认为是下滑
        // 这里可以执行阻止下滑的操作，比如阻止滚动条滚动等
        console.log('111');
        event.preventDefault()
      } else {
        // 左右滑动
        // 执行左右滑动的逻辑
        console.log('222');

      }
    },
  
    zsback() {
      console.log('9998');
      this.$router.back();
    },
    getlearningpath() {
      uploadFiles.learningpath().then((res)=> {
     
        if(res.data.code == 200) {
          console.log(res.data.datas);
          let arr = JSON.parse(JSON.stringify(res.data.datas))
          console.log(arr);

        let  min = Math.ceil(800);
        let  max = Math.floor(950);
        console.log();
        let sjs = 0
        
        
          this.list = []
          arr.map(item => {
            sjs = Math.floor(Math.random() * (max - min + 1)) + min
            this.list.push(
              {
              name:item.name,
              value:sjs,
       
              symbol:"image://http://yyjxweb.iwangbang.com/img/wxx.png"
             }
            )
             
          })
          this.geteCharts()
          console.log(this.list);
          
          
        }
      })
    },

    geteCharts() {
      var myChart = echarts.init(this.$refs.myDiv);
    //3. 指定图表的配置项和数据
    //该处就是图表内容，在官网的示例里面，要复制过来到项目里面的也是这一块内容
    var option = {
      grid: {
        left: '0%',  // 网格距离容器左侧的距离
        right: '0%', // 网格距离容器右侧的距离
        // 你也可以设置宽度：
        // width: '80%' // 这将设置网格的宽度为80%
    },
    
      xAxis: {
        axisLabel: {
          //坐标轴刻度标签
          show: false,
          interval: 300,
          boundaryGap: false,
          // margin:10
          // interval: 2
        },
        axisLine: {
          //坐标轴轴线
          show: false,
        },
        axisTick: {
          //坐标轴刻度
          show: false,
        },

        type: "category",
        data: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Z1', 'Z2', 'Z3', 'Z4']
      },
      yAxis: {
        type: "value",
        splitLine: {
          //坐标轴在grid区域中的分割线
          show: false,
        
        },
        axisLabel: {
          //坐标轴刻度标签
          show: false,
        },
      },
      series: [{
        data: this.list,
        type: 'line',
        smooth: true,
        lineStyle: {
            width: 3, // 设置线宽，影响点位置距离
            type: 'dashed',
        },
        symbolSize: [35, 50], // 设置标记的大小，这里是宽度和高度,
        symbolOffset: [0, '-35%'],
        label: {
            normal: {
                show: true,
                position: 'top', // 显示位置
                distance: 15, // 调整距离
                formatter: '{b}' // 标签文本格式器
            }
        },
        
     
    }]

    };
    //4.使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
    }
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.track-box {

  /* height: 100vh;
  background-image: url(../assets/xiaobangyy/xxgj/xxgjbj.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
}
.line {
  border-top: 1px solid black;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
}

.node {
  position: absolute;
  top: -5px; /* 节点的大小 */
  width: 10px;
  height: 10px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 50%;
}
#demo {
  position: relative;

z-index: 999;
}
.xxgjbj {
  position: absolute;
  left: 0;
  top: 0;
}
.zs-back {
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 0;
  margin-left: 20px;
  margin-top: 20px;
  width: 70px;
  height: 70px;
  margin-top: 30px;
  margin-left: 30px;
}
</style>
