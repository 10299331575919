<template>
  <div>
    <button @click="startkaishiluy" :disabled="isRecording">开始录音</button>
    <button @click="stopRecording" :disabled="!isRecording">停止录音</button>
    <button @click="playAudio" v-if="bflyRul" :disabled="isRecording">播放录音</button>
    <audio ref="audioPlayer" v-if="bflyRul" :src="bflyRul" controls></audio>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRecording: false,
      radiosjdskknae: null,
      lyChunks: [],
      bflyRul: null,
      islyfasfb: false, // 用于控制播放按钮的状态
    };
  },
  methods: {
    async startkaishiluy() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.radiosjdskknae = new MediaRecorder(stream);
        this.radiosjdskknae.ondataavailable = (event) => {
          this.lyChunks.push(event.data);
        };
        this.radiosjdskknae.onstop = () => {
          const audioBlob = new Blob(this.lyChunks, { type: 'audio/wav' });
          this.bflyRul = URL.createObjectURL(audioBlob);
          this.lyChunks = [];
        };
        this.radiosjdskknae.start();
        this.isRecording = true;
      } catch (error) {
        console.error('无法获取麦克风权限:', error);
      }
    },
    stopRecording() {
      if (this.radiosjdskknae) {
        this.radiosjdskknae.stop();
        this.isRecording = false;
      }
    },
    playAudio() {
      const audioPlayer = this.$refs.audioPlayer;
      if (this.islyfasfb) {
        // 如果正在播放，则暂停
        audioPlayer.pause();
        this.islyfasfb = false;
      } else {
        // 如果未播放，则开始播放
        audioPlayer.play();
        this.islyfasfb = true;
        audioPlayer.onended = () => {
          this.islyfasfb = false; // 播放结束后重置状态
        };
      }
    },
  },
};
</script>

<style scoped>
/* 添加一些样式 */
</style>