import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

import LearningTrajectory from '../components/LearningTrajectory.vue'
import Masonry from '../components/masonry.vue'
import MyTeacher from '../components/Myteacher.vue'
import OpenClass from '../components/openClass.vue'
import operation from '../components/operation.vue'
import aaaaaaaa from '../components/aaaaaaaa.vue'
import scheduleclass from '../components/scheduleclass.vue'










Vue.use(VueRouter)

const routes = [
  { path: '/',
    component: operation,
    meta: { title: '启动页' }
  },
    {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/teaching',
    name: 'teaching',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/teaching.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  { path: '/LearningTrajectory',
    component: LearningTrajectory,
    meta: { title: '学习轨迹' }
  },
  { path: '/masonry',
    component: Masonry,
    meta: { title: '钻石兑换榜' }
  },
  { path: '/Myteacher',
    component: MyTeacher,
    meta: { title: '我的老师' }
  },
  { path: '/openClass/:userId',
    component: OpenClass,
    name: 'OpenClass',

    meta: { title: '公开课' }
  },
  { path: '/aaaaaaaa',
    component: aaaaaaaa,
    meta: { title: '公开课' }
  },
  { path: '/scheduleclass',
    component: scheduleclass,
    meta: { title: '课程表' }
  },
 
  
  
  
]




const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',

  base: process.env.BASE_URL,
  routes
})

// // 在路由守卫中进行身份验证
// router.beforeEach((to, from, next) => {
//   // 检查用户是否需要登录
//   if (to.matched.some(record => record.meta.requiresAuth) && !isUserLoggedIn()) {
//     // 如果用户未登录，重定向到登录页面
//     next({
//       path: '/login',
//       query: { redirect: to.fullPath } // 将目的地路由作为查询参数传递
//     });
//   } else {
//     // 如果用户已登录，或者不需要登录，就正常导航
//     next();
//   }
// });
router.beforeEach((to, from, next) => {
  // 确保不会阻止组件的 mounted 钩子执行
  next();
});


export default router
