<template>
    <div class="yx-box">

    </div>
</template>
<script>
    export default {
        created() {
            setTimeout(()=> {
                this.$router.push('/login')
            },2000)
            
        }
    }
</script>

<style> 
    .yx-box {
        width: 100%;
        height: 100vh;
        background: url('../assets/xiaobangyy/start.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
    }
</style>