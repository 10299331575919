
<template>
  <div class="classroom">
    <!-- <div  @click="relearnbtn('请确认是否重新学习该节课')">11111111111111111111</div> -->
    <!-- <div class="room-header">
      <img
        class="back"
        src="../assets/xiaobangyy/gkk/save-as.png"
        alt=""
        @click="relearnbtn('请确认是否重新学习该节课')"
      />

      <div class="rom-rh">
        <div class="home-integral2" @click="openmasonry()">
          <img src="../assets/xiaobangyy/masonry.png" alt="" />
          <div class="grade">{{ usergrade }}</div>
        </div>
        <img
          class="back"
          src="../assets/xiaobangyy/gkk/my.png"
          alt=""
          @click="toteacher()"
        />
      </div>
    </div> -->
    <div
      class="room-body"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <div class="room-right">
        <img
          ref="image"
          @click="handleImageClick"
          class="figosasn"
          :src="kcimg"
        />
        <!-- <p
          class="kk-txt article"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd(item)"
          v-for="(item, index) in articleLines"
          :key="index"
          :ref="`line-${index}`"
        >
          {{ item.text }}
        </p> -->

        <!-- <button  @click="kaishixuexi">开始</button> -->

        <div>
          <!-- <button @click="startRecording">开始录音</button> -->
          <audio v-if="audioUrl" :src="audioUrl"></audio>

          <iframe
            ref="myIframe"
            :src="viewerUrl"
            frameborder="0"
            style="width: 50%; height: 100vh; display: none"
            id="my-iframe"
            @load="onIframeLoad('myIframe')"
          ></iframe>
          <!--   -->
        </div>

        <div style="display: none" @click="startSpeaking">111111</div>
        <iframe
          ref="langdu"
          :src="readAloudUrl"
          :key="iframeKey"
          style="width: 100%; height: 100vh; display: none"
          id="read-aloud"
          @load="onIframeLoad('langdu')"
        ></iframe>
        <!-- -->

        <!-- <button @click="ldRecording">开始录音</button> -->
        <audio
          style="display: none"
          v-if="aidourl"
          :src="aidourl"
          controls
        ></audio>

        <!-- <video preload muted src="https://qn.wby360.com/qsxbkwa.mp4" playsinline
                x5-video-player-type="h5"></video> -->
        <!-- <img style="width: 100%;height: 100%;" src="../assets/xiaobangyy/jximg.png" alt="">
                <div class="reward"> <img src="../assets/xiaobangyy/gkk/star.png" alt="">送星星</div> -->
      </div>

      <div class="room-left">
        <div
          class="room-head"
          :style="{ backgroundImage: 'url(' + teacherImg + ')' }"
        >
          <div class="statistics">
            <img class="stars" src="../assets/xiaobangyy/gkk/star.png" alt="" />
            <div class="rsfas">129</div>
          </div>
          <div class="roomname">George</div>
          <img
            v-if="mkfztas"
            class="mkfdk"
            src="../assets/xiaobangyy/gkk/microphone.png"
            alt=""
            @click="statusmkf('打开')"
          />
          <img
            v-else
            class="mkfdk"
            src="../assets/xiaobangyy/gbmkf.png"
            alt=""
            @click="statusmkf('关闭')"
          />
        </div>
        <div class="room-list">
          <div class="room-title">
            <div :class="{ this_xz: isActive == 1 }" @click="oncut('1')">
              座位表
            </div>
            <div
              style="position: relative"
              :class="{ this_xz: isActive == 2 }"
              @click="oncut('2')"
            >
              聊天室
              <div v-if="kasbf" class="messred"></div>
            </div>
          </div>
          <div class="room-box">
            <div v-if="isActive == 1 ? true : false" class="zwb-box">
              <div
                class="sdxccas"
                v-for="(item, index) in userList"
                :key="index"
              >
                <div>
                  <img class="zwlbiasf" :src="item.avatar" alt="" />
                  <div>{{ item.name }}</div>
                </div>
              </div>
            </div>
            <div v-else class="llt-box">
              <div class="tsc-txt">
                <span class="namebox">Joker星星</span>进入了教室
              </div>
              <div
                class="liaotianbox"
                v-for="(item, index) in chitchat"
                :key="index"
              >
                <div>
                  <div
                    class="username"
                    :style="
                      item.my == 1 ? 'text-align: right;' : 'text-align: left;'
                    "
                  >
                    {{ item.name }}
                  </div>
                  <div class="txtbox" :class="item.my == 1 ? 'rtxt' : 'ltxt'">
                    {{ item.text }}
                  </div>
                </div>
              </div>
              <div class="zsA-txt">
                老师送<span class="namebox">Joker星星</span>一颗砖石
              </div>
            </div>
          </div>
          <div class="zxrs">共{{ onLine }}人</div>
        </div>
      </div>
    </div>

    <!-- 出题弹窗 -->
    <el-dialog
      title="练习题"
      :visible.sync="dialogVisible"
      :before-close="xtClose"
      fullscreen
      width="45%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-form ref="form" :model="form">
        <el-form-item
          :class="'form-item-' + index"
          :label="item.question"
          v-for="(item, index) in topictext.options"
          :key="index"
        >
          <el-radio-group v-model="selectedOption">
            <el-radio :label="item">{{ item }}</el-radio>
          </el-radio-group>

          <!-- <el-radio-group
            class="adfjlable"
            v-model="item.myanswer"
            @change="handleRadioChange(index, $event)"
         
            :key="index"
          >
            <el-radio :ref="item" class="saug" :label="item" v-removeAriaHidden>
              <span
                :ref="'custom-label-' + index + ''"
                :class="'custom-label-' + index + ''"
                >{{ item }}</span
              >
            </el-radio>
          </el-radio-group> -->
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 单词拼写弹窗 -->
    <el-dialog
      title="单词拼写练习"
      fullscreen
      :visible.sync="wordVisible"
      width="45%"
      class="lxtlog"
      :close-on-click-modal="false"
      :show-close="false"
      :before-close="handleClose"
    >
      <el-button class="xxxyx" type="primary" @click="xxlickfavn()"
        >学习下一项</el-button
      >
      <div style="font-size: 25px" v-if="pingxiets">
        提示：
        <!-- <div class="word" v-for="(item,index) in specialArr" :key="index">
        <span class="letter">{{ item }}</span>
        </div> -->
        <div class="word-container" :class="{ 'full-word': showFullWord }">
          <div
            class="word"
            v-for="(item, index) in displayedLetters"
            :key="index"
          >
            <span class="letter">{{ item }}</span>
          </div>
        </div>
      </div>
      <el-form
        style="margin-top: 30px"
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        @submit.prevent="handleSubmit"
      >
        <el-form-item prop="wordxx">
          <el-input
            class="pxdcajs"
            ref="myInput"
            v-model="ruleForm.wordxx"
            @keyup.enter.native="handleEnter"
            @focus="handleFocus"
            autofocus
          ></el-input>
          <el-input style="display: none;"></el-input>
        </el-form-item>
        <el-form-item class="fasfxf" style="margin-top: 60px">
          <!-- <el-button
            class="tixjstb"
            type="danger"
            @click="anewpingdu()"
            >重新拼读</el-button
          > -->
          <el-button
            v-if="wordShow"
            type="danger"
            class="xsdcshow"
            @click="liworddahfg()"
            >显示单词</el-button
          >
          <el-button
            class="tixjstb"
            type="primary"
            @click="submitForm('ruleForm')"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 重新学习弹窗 -->
    <div class="xxbox" v-if="dljsas">
      <div class="asfjx">
        <img class="lajjs" src="../assets/xiaobangyy/pop/l-y.png" alt="" />
        <img class="rajjs" src="../assets/xiaobangyy/pop/r-y.png" alt="" />
        <div class="sdksnjg">
          <div class="jsxfd">
            <div class="vkah">提示</div>
          </div>
          <div style="width: 100%">
            <div class="aksuzjxf">
              {{ logtxt }}
              <br />
            </div>
            <div class="ksasgd">
              <div class="afhnf">
                <img
                  src="../assets/xiaobangyy/czbtn/qx.png"
                  alt=""
                  @click="guanbi"
                />
                <div class="btntxt" @click="guanbi">休息一会</div>
              </div>
              <div class="afhnf">
                <img
                  src="../assets/xiaobangyy/czbtn/qr.png"
                  alt=""
                  @click="ConfirmOk"
                />
                <div class="btntxt" @click="ConfirmOk">学习下一课</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 英语单词弹窗 -->
    <!-- 出题弹窗 -->
    <el-dialog
      :visible.sync="wordDisplay"
      width="100%"
      fullscreen
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div v-if="topicshow">
        <div class="saghdfsfas">
          <div class="yyimg">
            <img
              @click="palyyycasty"
              class="xinxiang"
              src="../assets/xiaobangyy/xinxiang.png"
              alt=""
            />
            <div class="box" @click="toggleAnimation">
              <div class="wifi-symbol" :class="{ animate: isAnimating }">
                <!-- <div class="wifi-circle first"></div> -->
                <div class="wifi-circle second"></div>
                <div class="wifi-circle third"></div>
              </div>
            </div>
          </div>

          <div>
            <span
              class="jfgdfh"
              :style="{ color: item.color }"
              v-for="(item, index) in ldbxzdtxt"
              :key="index"
              style="font-size: 28px"
              >{{ item.word }}</span
            >
          </div>
          <div class="csjdafaf">
            {{ wordcontent }}
          </div>

          <el-button class="nextTO" type="primary" @click="skiplearning"
            >学习下一项</el-button
          >
          <div class="czfs-box">
            <div class="khcxcy" v-if="ldadhfwg" @click="cliRecording">
              <img
                class="njkdgfgd"
                src="../assets/xiaobangyy/luyin.png"
                alt=""
              />
            </div>
            <div class="khcxcy" v-else @click="RecordingOk">
              <img
                class="njkdgfgd2"
                src="../assets/xiaobangyy/lyzimg.png"
                alt=""
              />
            </div>
          </div>

          <recorddh v-if="uxfASGNUG"></recorddh>
          <div v-else>
            <div class="sfdgmhrgj" v-if="bflyRul">
              <div class="clisdjfd">点击我可以播放自己的录音哦！</div>
              <div class="gjasfgeg">
                <img
                  @click="lybofang()"
                  :disabled="isRecording"
                  class="startly"
                  :src="radioUrlimg"
                  alt=""
                />
              </div>
              <audio
                style="display: none"
                ref="audioPlayer"
                v-if="bflyRul"
                :src="bflyRul"
                controls
              ></audio>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="">
       
        <div v-if="lxtshow" style="margin-top: 20px">
          
          <div style="text-align: left; margin: 10px 0; font-size: 25px">
            例题：
          </div>
          <el-form ref="form" :model="form">
           
            <el-form-item :label="topictext.question">
              <el-radio-group
                class="adfjlable"
                v-model="selectedOption"
                v-for="(item, index) in topictext.options"
                :key="index"
                :disabled="true"
              >
                <el-radio class="saug" :label="item" v-removeAriaHidden>
                  <!-- :class="'custom-label-' + index + ''" -->
                  <div
                    :ref="'custom-label-' + index + ''"
                    :class="{ 'correct-answer': isCorrectAnswer(item) }"
                  >
                    {{ item }}
                  </div>
                </el-radio>
              </el-radio-group>
              <div style="color: #409eff">
                <span>解析：</span> {{ topictext.explanation }}
              </div>
              <div class="zjlxdba">
                <el-button
                  class="adshgfjg"
                  type="danger"
                  @click="nounderstand()"
                  >不会</el-button
                >
                <el-button class="adshgfjg" type="primary" @click="yourselflx()"
                  >自己练习</el-button
                >
              </div>
              <div>
                {{ bhjsys }}
              </div>
            </el-form-item>
          </el-form>
        </div>

        <div v-else>
          <el-button class="xxsvad" type="primary" @click="skiplearning"
            >学习下一项</el-button
          >
          <!--  -->
          <el-form
            ref="form"
            :model="form"
            style="display: flex; justify-content: center; margin-top: 8%"
          >
            <el-form-item :label="topictext.question" class="custom-form-item">
              <el-radio-group
                :ref="item"
                class="adfjlable"
                v-model="xzselect"
                v-for="(item, index) in topictext.options"
                :key="index"
                :disabled="bcxzashf"
                @change="handchutiadsfh(index, $event)"
              >
                <el-radio class="saug" :label="item" v-removeAriaHidden>
                  <div
                    class="fakxb-fgcfg"
                    :ref="'custom-label-' + index + ''"
                    :class="'custom-label-' + index + ''"
                  >
                    {{ item }}
                  </div>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div v-if="jiexild" class="fahsgd">
            <div
              style="color: #409eff; font-size: 20px; width: 95%; margin: auto"
            >
              <span>解析：</span> {{ topictext.explanation }}
            </div>

            <el-button
              v-if="gsfjdugh"
              type="primary"
              class="xytbsfn"
              @click="nextQuestion"
              >下一题</el-button
            >
          </div>
        </div>
        <el-skeleton v-if="dgjfxdhsfu" :rows="12" animated  style="margin-top: -40px;"/>   
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as uploadFiles from "@/api/uploadFiles";
import recorddh from "../components/recorddh.vue";
export default {
  components: {
    recorddh,
  },
  mounted() {
    let xxnr = this.$route.params.userId;
    if (xxnr == "复习上一节课内容") {
      // 上次学习
      this.laststudy();
      this.sadsffm();
      this.myreview = true;
    } else {
      console.log(xxnr, ">>>>>>>>>>>>>>>");

      // this.kaishixuexi();
      this.kcxqlist(xxnr);
      this.myreview = false;
    }

    this.getmasonry();
    this.getAicourse();
    this.getmyaiteacher();

    // window.addEventListener("touchmove", this.preventBack, { passive: false });
    // window.onmessage = (event) => {
    //   // 根据上面制定的结构来解析iframe内部发回来的数据
    //   console.log(event.data,'333');

    //   if (typeof event.data == "object") return;
    //   const chineseRegex = /[\u4e00-\u9fa5]/;

    //   if (event.data.indexOf("webpackHotUpdate") !== -1) return;

    //   if (event.data == false) {
    //     // 当用户没有说出页数的返回
    //     this.getreinquiry();
    //   } else if (event.data == "打开") {
    //     // 当用户确认页数对；打开课本
    //     this.openBook();
    //   } else if (event.data == "不对") {
    //     // 当用户确认页数对；打开课本
    //     this.redeclare();
    //   } else if (event.data == "继续下一步操作") {
    //     this.exercisesOk();
    //   } else if (event.data == "朗读完成讲解短语的意思") {
    //     this.theMeaning();
    //   } else {
    //     // 如果用户说出了页数
    //     this.inquiryOk(event.data);
    //   }
    // };

    console.log(this.articleLines);
  },
  data() {
    return {
      isRecording: false,
      radiosjdskknae: null,
      lyChunks: [],
      bflyRul: null,
      islyfasfb: false, // 用于控制播放按钮的状态
      isAnimating: false,
      audioEnded: null,

      radioUrlimg: require("../assets/xiaobangyy/startly.png"),
      teacherImg: "",
      //
      textnr: null,
      dgjfxdhsfu:false,
      mediaReasfv: null,
      audioChaksfh: [],
      aidourl: "",
      auidoContaksfh: null,
      analyssdasf: null,
      sourshdba: null,
      langduYimer: null,
      hasAdioput: false,
      lxtshow: false,
      wordShow: false,
      isMoving: false,
      articleLines: [],
      highlightedText: [],
      startX: 0,
      startY: 0,
      gsfjdugh: true,
      currentLineIndex: -1,
      radiolad: null,
      //
      langdujudge: null,
      kcimg: null,
      sydoadngasun: 0,
      specialArr: [],
      displayedLetters: [],
      currentIndex: 0,
      showFullWord: false,
      pingxiets: false,
      bhjsys: "",
      uxfASGNUG: false,
      ldadhfwg: true,
      form: {
        resource: "",
      },
      moreincident: [],
      dhcontent: [],
      bhsunad: 0,
      asjfnc: 0,
      pxtisfn: "",
      dhData:'',
      requestPromise: null,
      jiexild: false,
      bcxzashf: false,
      loading: false,
      xzselect: "",
      topicshow: true,
      selectedOption: "",
      wordcontent: null,
      ldbxzdtxt: null,
      previousSelected: null,
      dljsas: false,
      presentId: null,
      phraseArr: null,
      suspend: true,
      wordDisplay: false,
      logtxt: "当前课程已经学习完毕，是否学习下一课！",
      myreview: false,
      topictext: [],
      operateype: "",
      dynamicStyle: "red",
      dialogVisible: false,
      wordVisible: false,
      usergrade: "***",
      aiddas: null,
      dhaids: "",
      updateaid: true,
      userpage: 0,
      mediaRecorder: null,
      yycpmediasjf: null,
      audio: null,
      audioChunks: [],
      csnumber: 0,
      audioUrl: "",
      audioContext: null,
      audiosjasfn: null,
      analyser: null,
      source: null,
      silenceTimer: null,
      hasAudioInput: false,
      viewerUrl: "/voiceText/index.html",
      readAloudUrl: "/score/example/ise/index.html",
      iframeKey: Date.now(),
      audioData: "",
      studycont: [],
      mkfztas: true,
      isActive: 1,
      onLine: 296,
      kasbf: true,
      courseList: [],
      selectedValue: "",
      zqanswers: "",
      ruleForm: {
        wordxx: "",
        original: "",
      },
      yeaksun: 0,
      userList: [],
      rules: {
        wordxx: [
          { required: true, message: "请拼写该单词", trigger: "blur" },
          // { validator: this.validateWord(), trigger: 'blur' }
        ],
      },
      // 判断的答题正确率
      accuracy: [],
      getlistzb: [],

      ontxt: 0,
      zqArr: [],
      cwArr: [],
      basdnArr: [],

      chitchat: [
        {
          name: "Hokeo",
          text: "We helped to createusable and attractivewebsiteswe helped to create",
          my: 1,
        },
        {
          name: "asfzz",
          text: "We helped to createusable and attractivewebsiteswe helped to create",
          my: 2,
        },
        {
          name: "Joker星星",
          text: "We helped to createusable and attractivewebsiteswe helped to create",
          my: 2,
        },
      ],
      zwblist: [
        {
          name: "Hokeo",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "asfzz",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "Hokedtkeo",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "tkfgkd",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "fjdtk",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "hgfd",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "hjghkes",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "ghlfg",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "etrdsh",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "sdgwr",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "rwewfg",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "sasfa",
          url: "../assets/xiaobangyy/logo.png",
        },
        {
          name: "jeasx",
          url: "../assets/xiaobangyy/logo.png",
        },
      ],
    };
  },
  methods: {
    // handleRadioChange(i, e) {
    //   console.log(this.ontxt);
    //   console.log(i, e);

    //   console.log(this.$refs[e][0].$el);
    //   // this.$refs[e][0].$el.querySelector('.custom-label-'+i).style.color = '';
    //   console.log();

    //   this.topictext.map((item) => {
    //     item.options.map((item2) => {
    //       if (e == item2) {
    //         console.log(item.options);
    //         console.log(item.answer, "正确答案");

    //         if (e == item.answer) {
    //           this.$message({
    //             message: "恭喜你答对了！",
    //             type: "success",
    //           });
    //           this.$refs["custom-label-" + i].forEach((item) => {
    //             console.log(item);

    //             item.style.color = "";
    //           });
    //           this.$refs[e][0].$el.parentElement.parentElement
    //             .querySelectorAll(".el-radio__inner")
    //             .forEach((item) => {
    //               console.log(item);

    //               item.style.borderColor = "";
    //               item.style.background = "";
    //             });

    //           console.log("111");
    //         } else {
    //           this.$refs["custom-label-" + i].forEach((item) => {
    //             console.log(item);

    //             item.style.color = "";
    //           });

    //           this.$refs[e][0].$el.parentElement.parentElement
    //             .querySelectorAll(".el-radio__inner")
    //             .forEach((item) => {
    //               console.log(item);

    //               item.style.borderColor = "";
    //               item.style.background = "";
    //             });
    //           this.$message.error("请在思考一下哦，当前选择的答案不对！");
    //           this.$refs[e][0].$el.querySelector(
    //             ".custom-label-" + i
    //           ).style.color = "red";
    //           let tx = this.$refs[e][0].$el.querySelector(".custom-label-" + i)
    //             .parentElement.previousElementSibling.children[0];
    //           tx.style.borderColor = "red";
    //           tx.style.background = "red";
    //         }
    //       }
    //     });
    //   });

    //   console.log(this.topictext);
    //   // 判断三道题是否都完成
    //   let arr = [];
    //   this.topictext.map((item) => {
    //     if (item.myanswer == item.answer) {
    //       arr.push(1);
    //       console.log(arr);
    //     }
    //   });
    //   if (arr.length == 3) {
    //     this.exercisesOk();
    //   }
    // },
    async getpasslxt(e) {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      if (this.moreincident.length == 0 ){
        let txt = e;

        uploadFiles.readAloud({ text: txt }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            if (this.audio) {
              this.audio.pause();
              this.audio.currentTime = 0;
            }
            this.audioData = res.data.datas.base64;

            this.readCnly();
            this.accuracy = [];
            this.cwArr = [];
            this.zqArr = [];

            this.topictext = [];
            this.selectedOption = [];
            this.xzselect = "";
            this.bcxzashf = false;
            this.jiexild = false;
            this.topicshow = true;
            this.wordDisplay = false;
            this.bflyRul = null;
            this.lxtshow = false;
          }
        });
      } else {
        let txt = e;

        uploadFiles.readAloud({ text: txt }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            if (this.audio) {
              this.audio.pause();
              this.audio.currentTime = 0;
            }
            this.audioData = res.data.datas.base64;
          }
        });

        let avs = await this.readCnly();
        if (avs) {
          this.accuracy = [];
          this.cwArr = [];
          this.zqArr = [];

          this.topictext = [];
          this.selectedOption = [];
          this.xzselect = "";
          this.bcxzashf = false;
          this.jiexild = false;
          this.topicshow = true;
          this.wordDisplay = false;
          this.bflyRul = null;
          this.lxtshow = false;
          this.setaichat(this.moreincident, "");
        }
      }
    },
    //下一题
    nextQuestion() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      this.topictext = [];
      this.dgjfxdhsfu = true
      this.xzselect = "";
      this.bcxzashf = false;
      this.jiexild = false;
      this.chutisuasn(this.phraseArr);
    },
    async handchutiadsfh(i, e) {
      let gasf = null;

      this.bcxzashf = true;
      const radio =
        this.$refs[e][0].$el.parentElement.querySelectorAll(".el-radio__inner");
      const radio2 =
        this.$refs[e][0].$el.parentElement.querySelectorAll(".el-radio__label");
      radio2.forEach((item) => {
        item.style.color = "red";
      });
      radio.forEach((item) => {
        item.style.borderColor = "red";
        item.style.background = "red";
      });

      let a =
        this.$refs[
          "custom-label-" + this.topictext.answer
        ][0].parentElement.parentElement.querySelector(".el-radio__inner");
      let b =
        this.$refs[
          "custom-label-" + this.topictext.answer
        ][0].parentElement.parentElement.querySelector(".el-radio__label");
      b.style.color = "#409EFF";
      a.style.borderColor = "#409EFF";
      a.style.background = "#409EFF";

      if (i == this.topictext.answer) {
        console.log(this.cwArr.length / this.zqArr.length);
        console.log(this.cwArr.length, this.zqArr.length);

        this.accuracy.push("正确");
        this.$message({
          message: "恭喜你答对了！",
          type: "success",
        });
        // 回答解析
        gasf = await this.setlangduhd("恭喜你答对了！");
        console.log(gasf);
      } else {
        this.accuracy.push("错误");
        gasf = await this.setlangduhd("当前选择的答案不对！");

        this.$message.error("当前选择的答案不对！");
      }

      if (this.accuracy.length >= 2) {
        // this.gsfjdugh = false
        console.log(this.cwArr.length / this.zqArr.length, "99999888888888");
        if (gasf) {
          if ((this.cwArr.length / this.zqArr.length).toFixed(2) == 0) {
            await this.getpasslxt(
              "这个点我们已经学会了，下一个没弄懂的地方是哪里呢，请圈画出来吧？"
            );
          } else {
            this.getpasslxt(
              "这个点我们已经学会了，下一个没弄懂的地方是哪里呢，请圈画出来吧？"
            );
          }
        }
      }

      // 判断真确率

      this.accuracy.map((item) => {
        if (item == "正确") {
          this.zqArr.push(item);
        } else {
          this.cwArr.push(item);
        }
      });
      console.log(this.cwArr.length / this.zqArr.length, "122222");
      console.log(this.accuracy.length);
      console.log(this.cwArr.length);
      console.log(this.zqArr.length);

      //
    },
    async setlangduhd(e) {
      let af = null;
      let txt = "";
      if (e == "恭喜你答对了！") {
        txt += "恭喜你答对了！" + this.topictext.explanation;
      } else {
        txt += "当前选择的答案不对哦！" + this.topictext.explanation;
      }

      this.jiexild = true;
      await uploadFiles.readAloud({ text: txt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.audioData = res.data.datas.base64;
        }
      });

      af = await this.readCnly();
      if (this.accuracy.length >= 4) {
        console.log(af, "IIIIIIIIIDDDDDDDDDDDDDDDDDDD");
        if (af) {
          this.getpasslxt(
            "这个点我们已经学会了，下一个没弄懂的地方是哪里呢，请圈画出来吧？"
          );
        }
        return;
      }
      console.log(af, "00000");

      return af;
    },
    // isCorrectAnswer(option) {
    //   return option === this.topictext.options[this.topictext.answer];
    // },
    // isIncorrectAnswer(option) {
    //   return this.xzselect === option && !this.isCorrectAnswer(option);
    // },

    async startkaishiluy() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.radiosjdskknae = new MediaRecorder(stream);
        this.radiosjdskknae.ondataavailable = (event) => {
          this.lyChunks.push(event.data);
        };
        this.radiosjdskknae.onstop = () => {
          const audioBlob = new Blob(this.lyChunks, { type: "audio/wav" });
          this.bflyRul = URL.createObjectURL(audioBlob);
          this.lyChunks = [];
        };
        this.radiosjdskknae.start();
        this.isRecording = true;
      } catch (error) {
        console.error("无法获取麦克风权限:", error);
      }
    },
    stopkaishiluy() {
      if (this.radiosjdskknae) {
        this.radiosjdskknae.stop();
        this.isRecording = false;
      }
    },
    lybofang() {
      this.radioUrlimg = require("../assets/xiaobangyy/underway.png");
      const audioPlayer = this.$refs.audioPlayer;
      if (this.islyfasfb) {
        // 如果正在播放，则暂停
        audioPlayer.pause();
        this.islyfasfb = false;
        this.radioUrlimg = require("../assets/xiaobangyy/startly.png");
      } else {
        // 如果未播放，则开始播放
        audioPlayer.play();
        this.islyfasfb = true;
        audioPlayer.onended = () => {
          this.radioUrlimg = require("../assets/xiaobangyy/startly.png");

          this.islyfasfb = false; // 播放结束后重置状态
        };
      }
    },

    cliRecording() {
      // 111111111111
      this.startkaishiluy();
      this.uxfASGNUG = true;
      this.ldadhfwg = false;
      this.SpeechEvaluation(this.phraseArr);
    },
    RecordingOk() {
      this.stopkaishiluy();
      this.uxfASGNUG = false;
      this.ldadhfwg = true;
      this.stopSpeaking();
    },

    //完成当前练习题
    exercisesOk() {
      if (this.myreview == true) {
        this.myreview = false;
        let aitxt = "同学上节课的内容已复习完成，请问今天该学哪一页呢？";
        uploadFiles.readAloud({ text: aitxt }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.audioData = res.data.datas.base64;
            this.playAudio();
          }
        });

        return;
      }

      console.log("0kkkk");

      this.zrltchat(this.phraseArr);

      // let txt =
      //   "这个点我们已经学会了，下一个没弄懂的地方是哪里呢，请圈画出来吧？";
      // uploadFiles.readAloud({ text: txt }).then((res) => {
      //   console.log(res);
      //   if (res.data.code == 200) {
      //     this.audioData = res.data.datas.base64;
      //     setTimeout(() => {
      //       this.readCnly();
      //     }, 1000);

      //     this.updateaid = true;
      //     this.dhaids = "";
      //     this.aiddas = "";
      //   }
      // });
    },
    // 没有说出页数回调
    getreinquiry() {
      console.log("==========-------------11111111111111");

      this.audioData = "";
      let txt = "同学请正确的说出你学到的页数，列如：今天我学到了第2页。";
      uploadFiles.readAloud({ text: txt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.audioData = res.data.datas.base64;
          this.playAudio();
        }
      });
    },
    // 询问是否确定
    inquiryOk(e) {
      // console.log(e);

      // let txt = "是否打开第" + e + "";
      // this.userpage = e;
      // uploadFiles.readAloud({ text: txt }).then((res) => {
      //   console.log(res);
      //   if (res.data.code == 200) {
      //     this.audioData = res.data.datas.base64;
      //     console.log(this.audioData);

      //     // 询问是否确定
      //     this.whether();
      //   }
      // });
      // this.whether();
      this.openBook();
    },
    // 只读
    readCnly() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      this.audio = new Audio();
      this.audio.src = this.audioData;
      this.audio.play();

      let isEnded = false;

      this.audio.onended = () => {
        console.log("结束了");
        isEnded = true;
      };
      // 返回一个 Promise，以便在调用 readCnly 时可以等待音频结束
      return new Promise((resolve) => {
        const checkEnded = () => {
          if (isEnded) {
            resolve(true); // 音频结束时 resolve Promise
          } else {
            setTimeout(checkEnded, 100); // 每 100ms 检查一次
          }
        };
        checkEnded();
      });
    },
    // 单独

    qjdialogue() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      this.audio = new Audio();
      this.audio.src = this.dhData;
      this.audio.play();

      let isEnded = false;

      this.audio.onended = () => {
        console.log("结束了");
        isEnded = true;
      };
      // 返回一个 Promise，以便在调用 readCnly 时可以等待音频结束
      return new Promise((resolve) => {
        const checkEnded = () => {
          if (isEnded) {
            resolve(true); // 音频结束时 resolve Promise
          } else {
            setTimeout(checkEnded, 100); // 每 100ms 检查一次
          }
        };
        checkEnded();
      });
    },

    whether() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      console.log(this.audioData);

      this.audio = new Audio();
      this.audio.src = this.audioData;
      this.audio.play();
      this.audio.onended = () => {
        console.log("结束了");
        this.startRecording("询问是否");
      };
    },
    // 进入问话
    playAudio() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      this.audio = new Audio();
      this.audio.src = this.audioData;
      this.audio.play();
      this.audio.onended = () => {
        console.log("结束了");
        this.startRecording("第一次进入");
      };
    },
    guidance(e) {
      console.log(e, "1112223333");
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      this.audio = new Audio();
      this.audio.src = this.audioData;
      this.audio.play();
      this.audio.onended = () => {
        console.log("结束了");
        // 朗读打分

        return;
        this.SpeechEvaluation(e);
      };
    },
    // 首次回答第几页
    yhreply() {
      let aitxt = "同学你好，我是你的AI老师，今天上课上到了哪一页呢?";
      uploadFiles.readAloud({ text: aitxt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.audioData = res.data.datas.base64;
          this.playAudio();
        }
      });
    },
    kaishixuexi() {
      this.yhreply();
    },
    fhback() {
      this.$router.back();
    },
    oncut(e) {
      this.isActive = e;
      if (e == 2) {
        this.kasbf = false;
      } else {
        this.kasbf = true;
      }
    },
    statusmkf(e) {
      console.log(e);
      if (e == "打开") {
        this.mkfztas = false;
      } else {
        this.mkfztas = true;
      }
    },

    getAicourse() {
      uploadFiles.aicourse().then((res) => {
        console.log(res);
        if (res.data.code == 200) {
        }
      });
    },
    kcxqlist(e) {
      console.log(e, "pppp");

      this.articleLines = [];
      this.getlistzb = [];
      uploadFiles.particulars({ id: e }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.getlistzb = res.data.datas.data;
          let data = JSON.parse(JSON.stringify(res.data.datas.data));
          console.log(data);
          this.kcimg = res.data.datas.thumb;
          this.kcid = res.data.datas.id;
          console.log(this.kcid);
          // 获取学习用户列表
          this.getcourseusers();

          data.map((item) => {
            let a = {
              text: item.content,
              id: item.id,
              action: item.action,
              keynote: item.keynote,
            };
            this.articleLines.push(a);
          });

          // 将每一行的文字拆分成单词，并用 span 包裹\
          // this.$nextTick(() => {
          //   this.articleLines.forEach((line, index) => {
          //     const lineElement = this.$refs[`line-${index}`][0];
          //     const words = line.text.split(" ");
          //     lineElement.innerHTML = words
          //       .map((word) => `<span>${word}</span>`)
          //       .join(" ");
          //   });
          // });

          //
        }
      });

      let a = "这节课哪个地方没听懂，可以圈出来让我来为你讲解！";
      uploadFiles.readAloud({ text: a }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.audioData = res.data.datas.base64;
          this.readCnly();
        }
      });
    },
    handleImageClick(event) {
      const image = this.$refs.image;
      const rect = image.getBoundingClientRect();
      const scaleX = image.naturalWidth / rect.width;
      const scaleY = image.naturalHeight / rect.height;

      const x = (event.clientX - rect.left) * scaleX;
      const y = (event.clientY - rect.top) * scaleY;
      console.log(x, y);
      // 判断
      this.checkNumber(x, y);
    },
    checkNumber(x, y) {
      console.log(x, y);

      this.basdnArr = [];
      this.moreincident = [];
      
      this.getlistzb.map((item) => {
        if (
          x >= item.coordinate.x &&
          x <= item.coordinate.x1 &&
          y >= item.coordinate.y &&
          y <= item.coordinate.y1
        ) {
          // console.log(item,'0000');
          if (item.action.indexOf(",") !== -1) {
            item.action = item.action.split(",");
          }
          this.basdnArr.push({
            text: item.content,
            id: item.id,
            action: item.action,
            keynote: item.keynote,
          });

          console.log(this.basdnArr.length);
          console.log(this.basdnArr);

          if (this.basdnArr.length == 1) {
            // if(typeof(this.basdnArr[0].action) !== 'string') {
            //   console.log('多事件！！！');
            //   this.moreincident = []
            //   this.basdnArr[0].action.map(item => {
            //     console.log(item);
            //     let arr = {
            //       action:item,
            //       id:this.basdnArr[0].id,
            //       keynote:this.basdnArr[0].keynote,
            //       text:this.basdnArr[0].text,
            //     }
            //     this.moreincident.push(arr)
            //   })
            //   console.log(this.moreincident);
            //  this.setaichat(this.moreincident,'');
            //  return
            // }else {
            // console.log('单个事件！！！');
            //   console.log(this.basdnArr[0]);
            //  this.setaichat(this.basdnArr[0],'');
            //  return
            // }
            //
          } else {
            console.log("多个！！！！！！！！！！！！！！");
          }
        }
      });

      if (this.basdnArr.length !== 0) {
        this.basdnArr.map((item2) => {
          console.log(item2, "066666");

          if (typeof item2.action == "object") {
            console.log(item2, "////");

            item2.action.map((sun) => {
              let arr = {
                action: sun,
                id: item2.id,
                keynote: item2.keynote,
                text: item2.text,
              };
              this.moreincident.push(arr);
            });
          } else {
            let arr = {
              action: item2.action,
              id: item2.id,
              keynote: item2.keynote,
              text: item2.text,
            };
            this.moreincident.push(arr);
          }
        });
        console.log(this.moreincident);

        this.setaichat(this.moreincident, "");
      }
    },
    getcourseusers() {
      uploadFiles.getcourseusers({ course_id: this.kcid }).then((res) => {
        console.log(res.data, "33333");
        if (res.data.code == 200) {
          this.userList = res.data.datas;
        }
      });
    },

    async startRecording(e) {
      this.resetRecordingState();
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
          this.audioUrl = URL.createObjectURL(audioBlob);
          this.audioChunks = [];
        };

        this.mediaRecorder.start();

        this.audioContext = new AudioContext();
        this.analyser = this.audioContext.createAnalyser();
        this.source = this.audioContext.createMediaStreamSource(stream);
        this.source.connect(this.analyser);

        this.checkVolume(e);
        const iframeWindow = this.$refs.myIframe.contentWindow;
        if (e == "第一次进入") {
          iframeWindow.postMessage("第一次进入", "*");
        } else if (e == "询问是否") {
          iframeWindow.postMessage("询问是否", "*");
        } else if (e == "解释意思") {
          iframeWindow.postMessage("解释意思", "*");
        } else {
          iframeWindow.postMessage("开始录音", "*");
        }
      } catch (error) {
        console.error("录音启动失败:", error);
      }
    },
    checkVolume(e) {
      const bufferLength = this.analyser.fftSize;
      const dataArray = new Uint8Array(bufferLength);

      const check = () => {
        this.analyser.getByteTimeDomainData(dataArray);
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          const amplitude = dataArray[i] / 128 - 1;
          sum += amplitude * amplitude;
        }
        const rms = Math.sqrt(sum / bufferLength);

        const volumeThreshold = 0.05; // 调整这个值来设置声音波动阈值

        if (rms > volumeThreshold) {
          // 设置音量阈值
          console.log("说话了");
          this.hasAudioInput = true;
          clearTimeout(this.silenceTimer);
          this.silenceTimer = null;
        } else {
          if (!this.hasAudioInput) {
            if (e == "第一次进入") {
              // 如果没有检测到声音输入，3秒后停止录音
              if (this.silenceTimer === null) {
                this.silenceTimer = setTimeout(() => {
                  this.stopRecording();
                  this.hasAudioInput = false;
                }, 3000); // 3秒内无语音输入自动停止
              }
            }
          } else {
            // 如果已经检测到声音输入，2秒后停止录音
            if (this.silenceTimer === null) {
              this.silenceTimer = setTimeout(() => {
                this.stopRecording();
              }, 2000); // 2秒内无语音输入自动停止
            }
          }
        }

        requestAnimationFrame(check);
      };

      check();
    },
    resetRecordingState() {
      // 重置录音状态
      this.mediaRecorder = null;
      this.audioChunks = [];
      this.audioUrl = "";
      this.audioContext = null;
      this.analyser = null;
      this.source = null;
      this.silenceTimer = null;
      this.hasAudioInput = false;
    },
    stopRecording() {
      if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
        this.mediaRecorder.stop();
        this.audioContext.close();
        console.log("页数结束");
        const iframeWindow = this.$refs.myIframe.contentWindow;
        iframeWindow.postMessage("停止录音", "*");
      }
    },
    openBook() {
      console.log(this.userpage, "*************");
      this.$message({
        message: "正在打开第" + this.userpage + "请稍等！",
        type: "success",
      });

      uploadFiles.likeinfo({ text: this.userpage }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.studycont = res.data.datas.data;
          let a = "这节课哪个地方没听懂，可以圈出来让我来为你讲解！";
          uploadFiles.readAloud({ text: a }).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.audioData = res.data.datas.base64;
              this.readCnly();
            }
          });
          this.kcxqlist(res.data.datas.id);
        }
      });
    },
    redeclare() {
      let txt = "同学请你重新说出你学到的页数，列如：今天我学到了第8页。";
      uploadFiles.readAloud({ text: txt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.audioData = res.data.datas.base64;
          this.playAudio();
        }
      });
    },

    handleTouchStart(event) {
      this.isMoving = false; // 初始化标志位
      console.log(event);

      // if(!this.suspend) return

      // console.log(this.suspend);

      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
      this.currentLineIndex = this.getLineIndex(this.startY);
    },
    handleTouchMove(event) {
      this.isMoving = true; // 设置标志位为正在滑动

      const moveX = event.touches[0].clientX;
      const moveY = event.touches[0].clientY;

      if (this.currentLineIndex !== -1) {
        const lineElement = this.$refs[`line-${this.currentLineIndex}`][0];
        const words = lineElement.querySelectorAll("span");

        words.forEach((word) => {
          const rect = word.getBoundingClientRect();
          if (
            moveX >= rect.left &&
            moveX <= rect.right &&
            moveY >= rect.top &&
            moveY <= rect.bottom
          ) {
            word.style.color = "red";
          }
        });
      }
    },
    handleTouchEnd(e) {
      if (!this.isMoving) {
        // 如果不在滑动中，则不执行任何操作
        return;
      }

      console.log(e, "9999888");
      // console.log(this.suspend,'111');

      //  if(!this.suspend) return
      //   this.suspend = false
      //   console.log(this.suspend);

      this.highlightedText = [];

      if (this.currentLineIndex !== -1) {
        const lineElement = this.$refs[`line-${this.currentLineIndex}`][0];
        const words = lineElement.querySelectorAll("span");

        words.forEach((word) => {
          if (word.style.color === "red") {
            this.highlightedText.push(word.textContent);
          }
        });
      }

      this.currentLineIndex = -1;
      var txt = "";
      this.highlightedText.map((item) => {
        txt += item + " ";
      });
      console.log(txt);
      // if(e.action == '朗读练习') {
      //   this.addstudylog(e,res.data.datas.content)
      //   let txt = "这一句咱们需要会读，它是什么意思，请跟我一起朗读"+e.text;
      //   uploadFiles.readAloud({ text: txt }).then((res) => {
      //     console.log(res);
      //     return
      //     if (res.data.code == 200) {
      //       this.audioData = res.data.datas.base64;
      //       this.guidance()
      //     }
      //   });
      // }else {

      // }
      this.setaichat(e, txt);
      // 调用操作方法

      this.isMoving = false;
    },
    getLineIndex(y) {
      for (let i = 0; i < this.articleLines.length; i++) {
        const lineElement = this.$refs[`line-${i}`][0];
        const rect = lineElement.getBoundingClientRect();
        if (y >= rect.top && y <= rect.bottom) {
          return i;
        }
      }
      return -1;
    },
    setaichat(e, txt) {
        //  提前调用ai对话衔接
        

      console.log(e, txt, "6669");
      console.log(e.length);

      if (e.length >= 1) {
        console.log(e);
        this.moreincident[0].action = this.moreincident[0].action.trim();
        e = this.moreincident[0];
        this.moreincident.shift();
      }
      console.log(this.moreincident);
  

      this.naturedh(e);
      console.log(e, "112");

    

      this.phraseArr = e;

      this.createAide();
      uploadFiles
        .aichatChat({
          param: 1,
          soft: "aicourse",
          content: e.text,
          keynote: e.keynote,
          action: e.action,
          course_id: this.kcid,
          aid: this.aiddas,
          dhid: this.dhaids,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            // 添加学习记录
            console.log(e, res.data.datas.content, "000000000000");
            this.addstudylog(e, res.data.datas.content);

            if (e.action == "单词拼写" || e.action == "单词拼读") {
              this.operateype = "单词拼写";
              // 调用单词拼写
              this.spellWords(res.data.datas.content, e.text);
              this.ruleForm.wordxx = "";
              return;
            } else if (e.action == "朗读练习") {
              // this.langdujudge = "朗读练习"
              this.operateype = "朗读练习";
              this.textnr = e.text;
              console.log(e.text);
              this.readAloudUrl =
                "/score/example/ise/index.html" + "?param1=" + this.textnr + "";
              console.log(res.data.datas.content, "]]]]]]]]]]]]");
              this.wordDisplay = true;
              this.wordcontent = res.data.datas.content;

              const punctuation = /[\.,!?]/g; // 匹配常见的标点符号

              this.ldbxzdtxt = e.text.replace(punctuation, "").toLowerCase();
              this.ldbxzdtxt = this.ldbxzdtxt.split(" ").map((word) => {
                return {
                  word: word,
                  color: "green",
                };
              });
              console.log(this.ldbxzdtxt);
              // 单独朗读按钮
              this.dasvhgread(e.text);

              // 直接朗读
              uploadFiles
                .readAloud({ text: res.data.datas.content })
                .then((rn) => {
                  console.log(rn, res, e);

                  if (rn.data.code == 200) {
                    this.audioData = rn.data.datas.base64;
                    this.guidance(e);
                  }
                });
            } else {
              console.log("))))))))))))))))))))))))))))))))))))))))))))))))");

              // 先跟读在进行后续操作
              // this.theMeaning();
              // this.setAuestion()

              // this.dasvhgread(e.text);
              this.operateype = "出题";
              this.textnr = e.text;
              this.readAloudUrl =
                "/score/example/ise/index.html" + "?param1=" + this.textnr + "";
              // this.earlierreadAloud(res.data.datas.content, e, txt);
              this.theMeaning("下面先由我为你讲解一道练习题吧！");
              this.dgjfxdhsfu = true
              this.wordDisplay = true;
              this.topicshow = false;

              this.gsfjdugh = true;
            }
          }
        });
    },

    suahsf(e) {
      console.log("22222222222");

      this.createAide();
      uploadFiles
        .aichatChat({
          param: 1,
          soft: "aitopic",
          content: e.text,
          keynote: e.keynote,
          action: e.action,
          course_id: this.kcid,
          aid: this.aiddas,
          dhid: "",
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            console.log(res.data.datas.content);
            let txt = JSON.parse(JSON.stringify(res.data.datas.content));
            console.log(txt);
            console.log(txt.indexOf("json"));

            if (txt.indexOf("json") !== -1) {
              txt = txt.slice(3, -3).replace("json", "");
            }
            txt = JSON.parse(txt);
            console.log(txt);

            Object.assign(txt, { myanswer: "" });

            //

            this.topicshow = false;
            this.lxtshow = true;
            // this.wordDisplay = true

            this.topictext = txt;
            this.dgjfxdhsfu = false
            this.selectedOption = this.topictext.options[this.topictext.answer];

            // 解析朗读
            this.AnalyticReading(this.topictext.explanation);

            this.isCorrectAnswer(this.topictext.options);
            console.log(txt, "00000000000");
          }
        });
    },
    isCorrectAnswer(option) {
      return option === this.topictext.options[this.topictext.answer];
    },
    AnalyticReading(txt) {
      uploadFiles.readAloud({ text: txt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
          }
          this.audio = new Audio();
          this.audio.src = res.data.datas.base64;
          this.audio.play();
          this.audio.onended = () => {
            console.log("结束了");
          };
        }
      });
    },
    yourselflx() {
      //讲解完毕出练习题
      this.setAuestion();
    },

    dasvhgread(e) {
      (this.radioUrlimg = require("../assets/xiaobangyy/startly.png")),
        (this.radiolad = null);
      uploadFiles.readAloud({ text: e }).then((rn) => {
        if (rn.data.code == 200) {
          this.radiolad = rn.data.datas.base64;
          // console.log(e, "1112223333");
          // if (this.audio) {
          //   this.audio.pause();
          //   this.audio.currentTime = 0;
          // }
          // this.audio = new Audio();
          // this.audio.src = this.radiolad;
          // this.audio.play();
          // this.audio.onended = () => {
          //   console.log("结束了");

          // };
        }
      });
    },
    palyyycasty() {
      if (this.radiolad == null) return;
      this.isAnimating = !this.isAnimating;
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      this.audio = new Audio();
      this.audio.src = this.radiolad;
      this.audio.play();
      this.audio.onended = () => {
        console.log("结束了");
        this.isAnimating = !this.isAnimating;
      };
    },
    toggleAnimation() {
      this.isAnimating = !this.isAnimating;
    },
    // 不理解
    nounderstand() {
      this.dgjfxdhsfu = false
      this.bhsunad += 1;
      if (this.bhsunad >= 3) {
        this.loading = false;
        let text = "这道题对你来说可能太难了，接下来让我们在学习下一题吧";
        uploadFiles.readAloud({ text: text }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            if (this.audio) {
              this.audio.pause();
              this.audio.currentTime = 0;
            }
            this.audio = new Audio();
            this.audio.src = res.data.datas.base64;
            this.audio.play();
            this.audio.onended = () => {
              console.log("结束了");
              this.moreincident = [];
              this.lxtshow = true;
              this.topicshow = true;

              this.bflyRul = null;
              this.bhsunad = 0;
              this.wordDisplay = false;
              this.bhjsys = "";
            };
          }
        });
        return;
      }

      let txt = "我们只需要理解他的意思就明白了：";
      let loggsd = false;
      uploadFiles.readAloud({ text: txt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
          }
          this.audio = new Audio();
          this.audio.src = res.data.datas.base64;
          this.audio.play();
          this.audio.onended = () => {
            loggsd = true;
            console.log("结束了");
          };
        }
      });

      uploadFiles
        .aichatChat({
          param: 1,
          soft: "jsyf",
          content: this.phraseArr.text,
          keynote: this.phraseArr.keynote,
          action: this.phraseArr.action,
          course_id: this.kcid,
          aid: this.aiddas,
          dhid: "",
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.bhjsys = res.data.datas.content;
            uploadFiles
              .readAloud({ text: res.data.datas.content })
              .then((res) => {
                console.log(res);
                if (res.data.code == 200) {
                  if (loggsd == true) {
                    if (this.audio) {
                      this.audio.pause();
                      this.audio.currentTime = 0;
                    }
                    this.audio = new Audio();
                    this.audio.src = res.data.datas.base64;
                    this.audio.play();
                    this.audio.onended = () => {
                      loggsd = true;
                      console.log("结束了");
                    };
                  }
                }
              });
          }
        });
    },
    setTheme(txt) {
      console.log(txt);

      uploadFiles.readAloud({ text: txt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
          }
          this.audio = new Audio();
          this.audio.src = res.data.datas.base64;
          this.audio.play();
          this.audio.onended = () => {
            console.log("结束了");
          };
        }
      });
      this.chutisuasn(this.phraseArr);
    },
    chutisuasn(e) {
      console.log("chuti");

      this.createAide();
      uploadFiles
        .aichatChat({
          param: 1,
          soft: "aitopic",
          content: e.text,
          keynote: e.keynote,
          action: e.action,
          course_id: this.kcid,
          aid: this.aiddas,
          dhid: this.dhaids,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            console.log(res.data.datas.content);
            let txt = JSON.parse(JSON.stringify(res.data.datas.content));
            console.log(txt);
            if (txt.indexOf("json") !== -1) {
              txt = txt.slice(3, -3).replace("json", "");
            }
            txt = JSON.parse(txt);

            Object.assign(txt, { myanswer: "" });

            //

            // this.wordDisplay = true
            console.log(this.topictext);
            this.dgjfxdhsfu = false

            this.topictext = txt;

            // // 解析朗读
            // this.AnalyticReading(this.topictext.explanation)

            // this.isCorrectAnswer(this.topictext.options)
            // console.log(txt, "00000000000");
          }
        });
    },
    theMeaning(txt) {
      console.log(txt);

      uploadFiles.readAloud({ text: txt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
          }
          this.audio = new Audio();
          this.audio.src = res.data.datas.base64;
          this.audio.play();
          this.audio.onended = () => {
            console.log("结束了");
          };
        }
      });
      this.suahsf(this.phraseArr);
    },

    // 先朗读在后续操作
    earlierreadAloud(x, e, text) {
      // 打开弹窗

      this.wordDisplay = true;
      this.bflyRul = null;

      this.wordcontent = x;

      const punctuation = /[\.,!?]/g; // 匹配常见的标点符号

      this.ldbxzdtxt = e.text.replace(punctuation, "").toLowerCase();
      this.ldbxzdtxt = this.ldbxzdtxt.split(" ").map((word) => {
        return {
          word: word,
          color: "green",
        };
      });
      console.log(this.ldbxzdtxt);

      console.log(x, e, text);
      this.phraseArr = null;
      this.phraseArr = e;

      uploadFiles.readAloud({ text: x }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
          }
          this.audioData = res.data.datas.base64;

          this.audio = new Audio();
          this.audio.src = this.audioData;
          this.audio.play();
          this.audio.onended = () => {
            console.log("结束了");
            // this.SpeechEvaluation();
            // this.startRecording("解释意思");
          };
        }
      });
    },
    // 出练习题
    setAuestion() {
      this.setTheme("下面我们来做几道练习题吧！");

      setTimeout(() => {
        this.lxtshow = false;
        this.topictext = [];
        this.dgjfxdhsfu = true
      }, 2000);
    },
    showLetters() {
      this.displayedLetters = [];
      this.currentIndex = 0;
      const interval = setInterval(() => {
        if (this.currentIndex < this.specialArr.length) {
          this.displayedLetters.push(this.specialArr[this.currentIndex]);
          this.currentIndex++;
        } else {
          clearInterval(interval);
          setTimeout(() => {
            this.showFullWord = true;
          }); // 等待所有字母显示完毕后再应用动画
        }
      }, 2000); // 每个字母显示2秒
    },
    // 单词拼写
    async spellWords(txt, word) {
      console.log(txt, word, "0000000");
      this.wordShow = false;
      this.pingxiets = false;
      this.audioData = "";

      let sun = this.dcpxrandom();
      console.log(sun);

      // let xtve = '接下来我将教你拼写单词，请点击下面的键盘跟我一起拼写吧！'
      // console.log(txt, word);
      word = word.replace(/\s+/g, "");
      this.ruleForm.original = word;
      this.wordVisible = true;

      this.$nextTick(() => {
        this.$refs.myInput.focus();
      },100)
      

      

      this.specialArr = Array.from(word);
      console.log(this.specialArr, "OOOIIIIIIIII");

      // specialArr
      // await uploadFiles.readAloud({ text: sun }).then((res) => {
      //   console.log(res);
      //   if (res.data.code == 200) {
      //     this.audioData = res.data.datas.base64;
      //     this.readCnly();
      //   }
      // });
      // const result = await this.readCnly();

      // console.log(result);

      // uploadFiles.wordspell({ word: word }).then((res) => {
      //   console.log(res);
      //   if (res.data.code == 200) {
      //     console.log('完成1111');

      //   uploadFiles.readAloud({ text: res.data.datas.text }).then((rn) => {
      //     console.log('完成2222');

      //       if(rn.data.code == 200) {
      //         if(result) {
      //           this.audioData = rn.data.datas.base64;
      //           this.readCnly();
      //         }

      //       }

      //     })
      //   }
      // });
      try {
        // 同时发送两个异步请求
        const [res1, res2] = await Promise.all([
          uploadFiles.readAloud({ text: sun }),
          uploadFiles.wordspell({ word: word }),
        ]);

        // 处理第一个请求的结果
        console.log(res1);
        if (res1.data.code == 200) {
          this.audioData = res1.data.datas.base64;
          await this.readCnly();
        }
        // 处理第二个请求的结果

        if (res2.data.code == 200) {
          console.log("完成1111");

          // 发送第二个请求包含的请求
          const rn = await uploadFiles.readAloud({
            text: res2.data.datas.text,
          });
          console.log("完成2222");

          if (rn.data.code == 200) {
            this.pxtisfn = rn.data.datas.base64;
            this.audioData = rn.data.datas.base64;
            this.showLetters();
            this.pingxiets = true;
            await this.readCnly();
          }
        }
      } catch (error) {
        console.error("请求失败:", error);
      }
    },
    // 生成dhid
    generateRandomCodee(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    createAide() {
      var num = this.generateRandomCodee(10);
      console.log(num);
      if (this.updateaid == true) {
        this.aiddas = num;

        this.updateaid = false;
        console.log(this.aiddas, this.dhaids);
        this.dhaids = this.aiddas;
        return;
      }
      this.dhaids = this.aiddas;
      this.aiddas = num;
      console.log(this.aiddas, this.dhaids);
    },
    preventBack(event) {
      event.preventDefault();
    },
    toteacher() {
      this.$router.push("/Myteacher");
    },
    getmasonry() {
      uploadFiles.jewelMy().then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.usergrade = res.data.datas.jewel;
        }
      });
    },
    openmasonry() {
      this.$router.push("/masonry");
    },
    relearnbtn(e) {
      uploadFiles.resetstudy({ course_id: this.kcid }).then((res) => {
        console.log(res, "000000000088888888888888");
        if (res.data.code == 200) {
        }
      });
    },
    // 检查当前课程是否学习完毕
    checkSchedule() {
      uploadFiles.checkstudycompleted({ course_id: this.kcid }).then((res) => {
        console.log(res, "000000000088888888888888");
        if (res.data.code == 200) {
          // 学习下一节课
          this.getnextcourseid();
        }
      });
    },
    getnextcourseid() {
      uploadFiles.getnextcourseid({ course_id: this.kcid }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.dljsas = true;
          // 学习下一节课
          this.kcid = res.data.datas.course_id;
        }
      });
    },
    // 完成一个添加学习记录
    addstudylog(e, tx) {
      console.log(e, tx, "00000000000033333333333");

      uploadFiles
        .addstudylog({
          course_id: this.kcid,
          data_id: e.id,
          type: "defult",
          data_score: 0,
          data_action: e.action,
          data_content: e.text,
          data_log: tx,
        })
        .then((res) => {
          console.log(res, "222222222222");
          if (res.data.code == 200) {
            // 检查是否学习完毕
            this.checkSchedule();
          }
        });
    },
    handleClose() {
      this.wordVisible = false;
    },
    xtClose() {
      this.dialogVisible = false;
    },
    async xxlickfavn() {
      this.loading = false;
      let txt = "没关系这对于你可能太难了,我们下次在学,现在进行下一个知识点吧";

      await uploadFiles.readAloud({ text: txt }).then((rv) => {
        console.log(rv);
        if (rv.data.code == 200) {
          this.audioData = rv.data.datas.base64;
        }
      });
      let afsa = await this.readCnly();
      console.log(afsa);
      if (afsa) {
        this.moreincident = [];
        this.wordShow = false;
        this.pingxiets = false;
        this.audioData = "";
        this.wordVisible = false;
      }
    },
    liworddahfg() {
      this.pingxiets = true;
      this.audioData = this.pxtisfn;
      this.csnumber += 1;
    },
    submitForm(formName) {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }

      this.$refs[formName].validate((valid) => {
        console.log(valid);

        if (valid) {
          console.log(this.ruleForm.original);
          // this.ruleForm.original
          this.ruleForm.original = this.ruleForm.original.replace(/\s+/g, "");
          this.ruleForm.original = this.ruleForm.original.toLowerCase();

          this.ruleForm.wordxx = this.ruleForm.wordxx.replace(/\s+/g, "");
          this.ruleForm.wordxx = this.ruleForm.wordxx.toLowerCase();
          if (this.ruleForm.wordxx !== this.ruleForm.original) {
            console.log(this.ruleForm.wordxx, this.ruleForm.original);

            this.$message.error("请在检查一下当前拼写的单词不正确哦！");
            this.asjfnc += 1;
            // if (this.asjfnc >= 2 && this.csnumber == 2) {
            //   this.pingxiets = true;
            //   this.audioData = this.pxtisfn;
            //   this.readCnly();
            //   this.csnumber += 1;
            // } else if (this.asjfnc >= 2 && this.csnumber == 4) {
            //   this.pingxiets = true;
            //   this.audioData = this.pxtisfn;
            //   this.readCnly();
            //   this.csnumber += 1;
            // }
          } else {
            this.asjfnc = 0;
            this.csnumber += 1;
            if (this.csnumber == 1) {
              this.$message({
                message: "恭喜你拼写正确！请再跟我拼写一次吧！",
                type: "success",
              });
              let asd = this.zcpxdcrandom();
              uploadFiles.readAloud({ text: asd }).then((rn) => {
                console.log(rn);
                if (rn.data.code == 200) {
                  this.audioData = rn.data.datas.base64;
                  this.readCnly();

                  setTimeout(() => {
                    this.audioData = this.pxtisfn;
                    this.readCnly();
                  }, 4500);
                }
              });

              this.ruleForm.wordxx = "";
            } else if (this.csnumber == 2) {
              this.wordShow = true;
              this.pingxiets = false;
              let dsf = this.ddpxrandom();
              this.$message({
                message: "哇你真棒！现在由你单独拼写一次吧!",
                type: "success",
              });

              this.ruleForm.wordxx = "";
              uploadFiles.readAloud({ text: dsf }).then((rn) => {
                console.log(rn);
                if (rn.data.code == 200) {
                  this.audioData = rn.data.datas.base64;
                  this.readCnly();
                }
              });
            } else if (this.csnumber == 3) {
              this.$message({
                message: "拼写正确！",
                type: "success",
              });

              
              this.csnumber = 0;
              if (this.moreincident.length == 0) {
                let txt =
                  "恭喜你拼写正确，这个点我们已经学会了，下一个没弄懂的地方是哪里呢，请圈画出来吧？";
                uploadFiles.readAloud({ text: txt }).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.ruleForm = {
                      wordxx: "",
                      original: "",
                    };
                    this.audioData = res.data.datas.base64;
                    this.readCnly();
                    setTimeout(() => {
                      this.wordVisible = false;
                    }, 4500);
                  }
                });
                this.loading = false;
              } else {
                let txt = "这个点我们已经学会了，现在来学习下一项吧！";
                uploadFiles.readAloud({ text: txt }).then(async (res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.audioData = res.data.datas.base64;
                    this.ruleForm = {
                      wordxx: "",
                      original: "",
                    };
                    let a = await this.readCnly();
                    if (a) {
                      this.wordVisible = false;
                      this.loading = true;
                      this.setaichat(this.moreincident, "");
                    }
                  }
                });

                return;
              }
            } else if (this.csnumber == 4) {
              this.pingxiets = false;
              let dsf = this.ddpxrandom();
              this.$message({
                message: "哇你真棒！现在由你单独拼写一次吧!",
                type: "success",
              });

              this.ruleForm.wordxx = "";
              uploadFiles.readAloud({ text: dsf }).then((rn) => {
                console.log(rn);
                if (rn.data.code == 200) {
                  this.audioData = rn.data.datas.base64;
                  this.readCnly();
                }
              });
            } else if (this.csnumber > 4) {
              this.$message({
                message: "拼写正确！",
                type: "success",
              });

              
              this.csnumber = 0;

              if (this.moreincident.length == 0) {
                let txt = "这个单词请你拿个本子抄写下来，我们下次再来学习它吧";
                uploadFiles.readAloud({ text: txt }).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.ruleForm = {
                      wordxx: "",
                      original: "",
                    };
                    this.audioData = res.data.datas.base64;
                    this.readCnly();
                    setTimeout(() => {
                      this.wordVisible = false;
                    }, 4500);
                  }
                });
                this.loading = false;
              } else {
                let txt =
                  "我们现在来学习下一项，这个单词请你拿个本子抄写下来，我们下次再来学习它吧";
                uploadFiles.readAloud({ text: txt }).then(async (res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.audioData = res.data.datas.base64;
                    this.ruleForm = {
                      wordxx: "",
                      original: "",
                    };
                    let a = await this.readCnly();
                    if (a) {
                      this.wordVisible = false;
                      this.loading = true;
                      this.setaichat(this.moreincident, "");
                    }
                  }
                });
              }

              
            }
          }
        } else {
          console.log("error submit!!");
          this.$message.error("不能提交空的单词哦！");
          return false;
        }
      });
    },

    anewpingdu() {
      uploadFiles.wordspell({ word: this.ruleForm.original }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          uploadFiles.readAloud({ text: res.data.datas.text }).then((rn) => {
            console.log(rn);
            if (rn.data.code == 200) {
              this.audioData = rn.data.datas.base64;
              this.readCnly();
            }
          });
        }
      });
    },
    //   validateWord(word) {

    //    this.$nextTick(()=> {
    //     console.log(word);
    //     if(word == undefined)return

    //     return (rule, value, callback) => {
    //     if (value === word) {
    //       callback(); // 验证通过
    //     } else {
    //       callback(new Error('请在检查一下当前拼写的单词不正确哦！'));
    //     }
    //   };
    //    })

    // }
    sadsffm() {
      let txt = "让我们来复习一下上节课所学的内容吧！";
      uploadFiles.readAloud({ text: txt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.audioData = res.data.datas.base64;
          this.readCnly();
        }
      });
    },
    laststudy() {
      uploadFiles.laststudy().then((res) => {
        console.log(res, "222222222222");
        if (res.data.code == 200) {
          uploadFiles
            .aichatChat({
              param: 1,
              soft: "course_topic",
              content: "",
              keynote: "",
              action: "",
              course_id: res.data.datas.course_id,
              aid: this.aiddas,
              dhid: "",
            })
            .then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                console.log(res.data.datas.content);
                let txt = JSON.parse(JSON.stringify(res.data.datas.content));
                console.log();
                txt = txt.slice(3, -3).replace("json", "");
                txt = JSON.parse(txt);
                txt.map((item) => {
                  Object.assign(item, { myanswer: "" });
                });
                //
                this.topictext = txt;
                console.log(txt, "00000000000");
                this.dialogVisible = true;
              }
            });
        } else if (
          res.data.code == 500 &&
          res.data.msg == "未查到相关学习记录"
        ) {
          // 开始播放语音
          // this.kaishixuexi()
        }
      });
    },
    ConfirmOk() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      this.dljsas = false;
      this.kcxqlist(this.kcid);
    },
    guanbi() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      console.log("111222");

      this.dljsas = false;
      this.$router.push("/home");
    },

    // 开始
    startSpeaking() {
      const iframeWindow = this.$refs.langdu.contentWindow;
      iframeWindow.postMessage("开始录音", "*");
    },
    //结束
    stopSpeaking() {
      this.pcstopRecord();
      const iframeWindow = this.$refs.langdu.contentWindow;
      iframeWindow.postMessage("停止录音", "*");
      console.log("停止说话");
    },

    // 朗读评测
    SpeechEvaluation(e) {
      //
      // this.ldRecording();
      this.startSpeaking();
    },
    // 接受ifname数据
    onIframeLoad(iframeRef) {
      // 获取iframe的window对象
      const iframeWindow = this.$refs[iframeRef].contentWindow;

      window.addEventListener("message", (event) => {
        if (event.source === iframeWindow) {
          console.log("Received message from iframe:", event.data);
          if (iframeRef === "myIframe") {
            console.log(event.data, "---------/////////////");
            // else if (event.data == "打开") {
            //   // 当用户确认页数对；打开课本
            //   this.openBook();
            // }
            if (event.data == false) {
              // 当用户没有说出页数的返回
              this.getreinquiry();
            } else if (event.data == "不对") {
              // 当用户确认页数对；打开课本
              this.redeclare();
            } else if (event.data == "继续下一步操作") {
              this.exercisesOk();
            } else if (event.data == "朗读完成讲解短语的意思") {
              this.theMeaning();
            } else if (event.data == "没检测到声音") {
              this.jcnobody();
            } else {
              // 如果用户说出了页数
              this.userpage = event.data;

              this.inquiryOk(event.data);
            }
          }

          // 在这里处理接收到的消息
        }
      });

      // 监听message事件
      window.onmessage = (event) => {
        if (event.source === iframeWindow) {
          if (iframeRef === "langdu") {
            console.log(event.data, "++++++++");
            if (event.data == "正确") {
              console.log(
                this.operateype,
                "//////////000000000000000///////////"
              );

              if (this.operateype == "朗读练习") {
                this.exercisesOk();
              } else if (this.operateype == "出题") {
                console.log("99988888");
                this.theMeaning("下面先由我为你讲解一道练习题吧！");
              }

              return;
            }
            if (event.data.length) {
              // 朗读出错
              console.log(
                event.data,
                "88$$$$$1111111111111111111111111111111111"
              );
              const array = [];
              event.data.map((item) => {
                if (item.includes("&apos;")) {
                  item = item.replace(/&apos;/g, "'");
                  console.log(item);
                }
                array.push(item);
              });

              this.ReadAgain(array);
            }
          }
        }
      };
    },
    jcnobody() {
      this.sydoadngasun += 1;
      if (this.sydoadngasun >= 3) {
        let text =
          "同学由于你没有说出正确的页数，请你找到你的课本确认今天所学的页数再来与我学习吧！";
        uploadFiles.readAloud({ text: text }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.audioData = res.data.datas.base64;
            this.readCnly();
            setTimeout(() => {
              this.$router.push("/home");
            }, 4500);
          }
        });
        this.sydoadngasun = 0;
        return;
      }
      console.log("==========-------------11111111111111");

      this.audioData = "";
      let asf = this.yesdabjf();

      uploadFiles.readAloud({ text: asf }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.audioData = res.data.datas.base64;
          this.playAudio();
        }
      });
    },

    ReadAgain(e) {
      console.log(e, "-------------");

      e.map((item) => {
        item.toLowerCase();
      });
      this.ldbxzdtxt.forEach((item) => {
        if (e.includes(item.word)) {
          item.color = "red";
        } else {
          item.color = "green";
        }
      });
      console.log(this.ldbxzdtxt);

      if (e.devtoolsEnabled == true) return;
      let arr = "";
      let multi = [];
      e.map((item) => {
        console.log(item);
        arr += item + "、";
        multi.push(item);
      });
      console.log(multi, "00000");
      let sun = "";
      if (multi.length > 1) {
        if (multi.indexOf("多个单词发音问题") !== -1) {
          sun = e[0];
          console.log(multi, "111111111111111");
          multi.pop();
          console.log(multi, "222222222222222");

          console.log(sun, arr);
          // this.viewerUrl='/score/example/ise/index.html'+'?param1='+stringify(multi)+'',
          const iframe = document.getElementById("read-aloud").contentWindow;
          iframe.postMessage(multi, "*");
          console.log(sun, "88888");
          uploadFiles.readAloud({ text: sun }).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.audioData = res.data.datas.base64;
              this.readCnly();
            }
          });

          return;
        }
        let dxf = this.pdsjdags(arr, e);

        console.log(sun, arr);
        // this.viewerUrl='/score/example/ise/index.html'+'?param1='+stringify(multi)+'',
        const iframe = document.getElementById("read-aloud").contentWindow;
        iframe.postMessage(multi, "*");

        uploadFiles.readAloud({ text: dxf }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.audioData = res.data.datas.base64;

            this.readCnly();
          }
        });
      } else {
        console.log(multi, "007777");
        if (multi[0] == "多个单词发音问题") {
          if (this.operateype == "出题") {
            this.theMeaning("下面先由我为你讲解一道练习吧！");
          } else {
            this.exercisesOk();
          }

          // multi = [];
          // console.log(multi, "最后");
          // console.log(this.textnr, "1112222222222");

          // let tdas = this.langdusj();
          // sun = tdas + this.textnr + " ";

          // // 更新 key 的值以强制重新渲染 iframe
          // this.iframeKey = Date.now();
          // this.readAloudUrl =
          //   "/score/example/ise/index.html" + "?param1=" + this.textnr + "";

          // uploadFiles.readAloud({ text: sun }).then((res) => {
          //   console.log(res);
          //   if (res.data.code == 200) {
          //     this.audioData = res.data.datas.base64;
          //     this.readCnly()
          //   }
          // });

          return;
        } else {
          console.log("一个单词");

          // this.viewerUrl='/score/example/ise/index.html'+'?param1='+e[0]+'',
          let sjdg = this.ygdcsjga(arr, e);

          // this.viewerUrl='/score/example/ise/index.html'+'?param1='+e[0]+'',
          const iframe = document.getElementById("read-aloud").contentWindow;
          iframe.postMessage(multi, "*");

          uploadFiles.readAloud({ text: sjdg }).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.audioData = res.data.datas.base64;
              // 语音检测专用

              this.readCnly();
            }
          });
        }
      }
    },
    audiograde() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      this.audio = new Audio();
      this.audio.src = this.audioData;
      this.audio.play();
      this.audio.onended = () => {
        this.SpeechEvaluation();
      };
    },

    async ldRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.mediaReasfv = new MediaRecorder(stream);

        this.mediaReasfv.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChaksfh.push(event.data);
          }
        };

        this.mediaReasfv.onstop = () => {
          const audioBlob = new Blob(this.audioChaksfh, { type: "audio/wav" });
          this.aidourl = URL.createObjectURL(audioBlob);
          this.audioChaksfh = [];
        };

        this.mediaReasfv.start();

        this.auidoContaksfh = new AudioContext();
        this.analyssdasf = this.auidoContaksfh.createAnalyser();
        this.sourshdba = this.auidoContaksfh.createMediaStreamSource(stream);
        this.sourshdba.connect(this.analyssdasf);

        this.cpcheckVolume();
      } catch (error) {
        console.error("录音启动失败:", error);
      }
    },
    cpcheckVolume() {
      const bufferLength = this.analyssdasf.fftSize;
      const dataArray = new Uint8Array(bufferLength);

      const check = () => {
        this.analyssdasf.getByteTimeDomainData(dataArray);
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          const amplitude = dataArray[i] / 128 - 1;
          sum += amplitude * amplitude;
        }
        const rms = Math.sqrt(sum / bufferLength);

        if (rms > 0.1) {
          // 设置音量阈值
          this.hasAdioput = true;
          clearTimeout(this.langduYimer);
          this.langduYimer = null;
        } else if (this.hasAdioput) {
          if (this.langduYimer === null) {
            this.langduYimer = setTimeout(() => {
              this.pcstopRecord();
            }, 2000); // 2秒内无语音输入自动停止
          }
        }

        requestAnimationFrame(check);
      };

      check();
    },
    pcstopRecord() {
      if (this.mediaReasfv && this.mediaReasfv.state !== "inactive") {
        this.mediaReasfv.stop();
        this.auidoContaksfh.close();
        this.stopSpeaking();
      }
    },
    handleSubmit() {
      // 处理提交逻辑
      console.log("Form submitted:", this.inputValue);
    },
    handleEnter(event) {
      // 回车键的处理逻辑
      event.preventDefault(); // 阻止默认行为
   
    },

    // 单词拼写随机
    dcpxrandom() {
      let text = "";
      let arr = [
        "接下来我将教你拼写单词，请点击下面的键盘跟我一起拼写吧！",
        "现在该拼写单词了，请点击下面的键盘跟我一起拼写吧！",
        "这个单词要掌握拼写，请点击下面的键盘跟我一起拼写吧！",
        "这个单词需要你来跟我一起拼写，请点击下面的键盘跟我一起拼写吧！",
        "现在需要单词拼写做好准备了吗？，请点击下面的键盘跟我一起拼写吧！",
      ];

      const randomIndex = Math.floor(Math.random() * arr.length);
      text = arr[randomIndex];
      return text;
    },
    zcpxdcrandom() {
      let text = "";
      let arr = [
        "恭喜你拼写正确！请再跟我拼写一次吧！",
        "你真棒，请再跟我拼写一次吧！",
        "感觉你快要掌握这个单词了，请再跟我拼写一次吧！",
        "记住这个单词了吗，请再跟我拼写一次吧！",
        "请再跟我拼写一次这个单词巩固一下吧！",
      ];

      const randomIndex = Math.floor(Math.random() * arr.length);
      text = arr[randomIndex];
      return text;
    },
    ddpxrandom() {
      let text = "";
      let arr = [
        "你真棒现在由你单独拼写一次吧！",
        "下面由你进行单独拼写吧！",
        "接下来由你单独拼写吧",
        "这个单词由你单独来拼写一次吧",
        "难度升级了，接下来由你单独拼写吧！",
      ];

      const randomIndex = Math.floor(Math.random() * arr.length);
      text = arr[randomIndex];
      return text;
    },
    langdusj() {
      let text = "";
      let arr = [
        "你非常棒，我们再整体读一遍： ",
        "不错哟接下来我们在整体读一遍",
        "再跟我整体读一遍吧！",
        "加油再跟我继续读一遍吧",
        "你已经很棒了，来再跟我一起读一遍吧！",
      ];

      const randomIndex = Math.floor(Math.random() * arr.length);
      text = arr[randomIndex];
      return text;
    },
    pdsjdags(arr, e) {
      let text = "";
      let ord = [
        "你很不错呦！只是" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确。来，跟我一起读一遍吧：" +
          e[0] +
          "",
        "同学不对哦" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确。快和我一起读一遍吧：" +
          e[0] +
          "",
        "接下来将" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确，和我重新朗读一遍吧：" +
          e[0] +
          "",
        "同学" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确，和我重新朗读一遍吧：" +
          e[0] +
          "",
        "刚刚朗读的部分" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确，和我重新朗读一遍吧：" +
          e[0] +
          "",
      ];

      const randomIndex = Math.floor(Math.random() * ord.length);
      text = ord[randomIndex];
      return text;
    },
    ygdcsjga(arr, e) {
      let text = "";
      let ord = [
        "这里面只有" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确。来跟我一起读一遍吧：" +
          e[0] +
          "",
        "只是" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确。快和我一起读一遍吧：" +
          e[0] +
          "",
        "接下来将" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确，和我重新朗读一遍吧：" +
          e[0] +
          "",
        "同学" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确，和我重新朗读一遍吧：" +
          e[0] +
          "",
        "刚刚朗读的部分" +
          arr +
          "这" +
          e.length +
          "个单词的发音不是很准确，和我重新朗读一遍吧：" +
          e[0] +
          "",
      ];

      const randomIndex = Math.floor(Math.random() * ord.length);
      text = ord[randomIndex];
      return text;
    },
    yesdabjf() {
      if (this.yeaksun >= 2) {
        return;
      }
      let text = "";
      let ord = [
        "你好同学，你还在吗请你打开课本看看页数跟我一起学习吧!",
        "你好呀，如果不知道页数的话，请你打开课本翻到今天学到的课文看看页数与我对话吧",
        "你还没有回答我今天学到的页数呢，列如今天我学到了第2页！",
        "你好呀，如果不知道页数的话请你打开课本找到今天所学的内容，看看页数再与我一起学习吧",
        "请你正确的说出页数，例如今天我学到了第8页",
      ];

      const randomIndex = Math.floor(Math.random() * ord.length);
      text = ord[randomIndex];
      return text;
    },
   async zrltchat(e) {

    let sg = await this.qjdialogue()
    console.log(sg);
    if(sg) {
      console.log(this.moreincident,'000005555');
      
        this.updateaid = true;
        this.dhaids = "";
        this.aiddas = "";
        this.dialogVisible = false;
        this.wordDisplay = false;
        this.bflyRul = null;
        if(this.moreincident.length !== 0) {
          this.loading = true
          this.setaichat(this.moreincident,'');
        }else {
          this.loading = false
          
        }
    }
    

    return



      this.createAide();
      uploadFiles
        .aichatChat({
          param: 1,
          soft: "zrlt",
          content: e.text,
          keynote: e.keynote,
          action: e.action,
          course_id: this.kcid,
          aid: this.aiddas,
          dhid: this.dhaids,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            uploadFiles
              .readAloud({ text: res.data.datas.content })
              .then(async (rv) => {
                console.log(rv);
                if (rv.data.code == 200) {
                  this.audioData = rv.data.datas.base64;
                  // let afsa = await this.readCnly();
                  // if(afsa) {
                  //   this.updateaid = true;
                  //   this.dhaids = "";
                  //   this.aiddas = "";
                  //   this.dialogVisible = false;
                  //   this.wordDisplay = false;
                  //   this.bflyRul = null;
                  //   if(this.moreincident != []) {
                  //     this.loading = true
                  //     this.setaichat(this.moreincident,'');
                  //   }else {
                  //     this.loading = false
                  //   }

                  // }
                }
              });
          }
        });
    },
    async skiplearning() {
      this.loading = false;
      this.dgjfxdhsfu = false
      let txt = "没关系这对于你可能太难了 我们下次在学 现在进行下一个知识点吧";

      await uploadFiles.readAloud({ text: txt }).then((rv) => {
        console.log(rv);
        if (rv.data.code == 200) {
          if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
          }
          this.audioData = rv.data.datas.base64;
        }
      });
      let afsa = await this.readCnly();
      console.log(afsa);
      if (afsa) {
        this.moreincident = [];
        this.updateaid = true;
        this.dhaids = "";
        this.aiddas = "";
        this.dialogVisible = false;
        this.wordDisplay = false;
        this.bflyRul = null;
        this.lxtshow = true;
        this.topicshow = true;
        this.bhjsys = "";
      }
    },
    lxfaichat() {},
    handleFocus() {
      this.$refs.myInput.$el.querySelector("input").focus();
    },
    getmyaiteacher() {
      uploadFiles.aiteacherGet().then((res) => {
        if (res.data.code == 200) {
          console.log(res);
          this.teacherImg = res.data.datas.img;
        }
      });
    },
    naturedh(e) {
      console.log(e,'222223333333333');
      
      if (e.action == "朗读练习") {
        this.createAide();
        uploadFiles
          .aichatChat({
            param: 1,
            soft: "zrlt",
            content: e.text,
            keynote: e.keynote,
            action: e.action,
            course_id: this.kcid,
            aid: this.aiddas,
            dhid: this.dhaids,
          })
          .then((res) => {
            console.log(res,'KKKKKKKKKKKKKKKKKKKKKKK');
            if (res.data.code == 200) {
              uploadFiles
                .readAloud({ text: res.data.datas.content })
                .then(async (rv) => {
                  console.log(rv,'2563555555555555');
                  if (rv.data.code == 200) {
                    this.dhData = await rv.data.datas.base64;
                  }
                });
            }
          });
      }
    },
  },

  beforeDestroy() {
    window.onmessage = null;
    window.removeEventListener("touchmove", this.preventBack);
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
    this.audio = null;
    // this.controller.abort();
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.classroom {
  width: 100%;
  height: 100vh;
  background-image: url(/img/wdlsbj.3f49ace2.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 5px 15px;
  display: flex;
  align-items: center;
}
.room-header {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rom-rh {
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: space-between;
}
.back {
  width: 50px;
  height: 50px;
}
.serial {
  width: 180px;
  background: #0156af;
  color: #dad8d8;
  font-size: 14px;
  padding: 5px;
  border-radius: 20px;
}
.serial3 {
  width: 120px;
  background: #0156af;
  color: #dad8d8;
  font-size: 14px;
  padding: 5px;
  border-radius: 20px;
}
.room-body {
  width: 100%;
  height: 92%;
  display: flex;
}
.room-left {
  width: 250px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.room-head {
  width: 100%;
  height: 250px;
  border-radius: 15px;
  background-image: url("../assets/xiaobangyy/jxhead.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}
.roomname {
  position: absolute;
  width: 100px;
  height: 35px;
  background: red;
  bottom: 0;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mkfdk {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 5px;
  margin-bottom: 2px;
  width: 45px;
  height: 45px;
}
.room-list {
  width: 100%;
  height: calc(100% - 250px);
  border-radius: 15px;
  background: #005ab9;
  margin-top: 10px;
}
.room-right {
  flex: 1;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 15px solid #005ab9;
  background: #fff;

  position: relative;
  padding: 15px;
  overflow-y: auto;
  margin-right: 15px;
}
.figosasn {
  width: 100%;
}

.reward {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 120px;
  height: 50px;
  border-top-left-radius: 20px;
  display: flex;
  align-items: center;
  color: red;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;

  background: pink;
}
.statistics {
  position: relative;
  width: 80px;
  height: 30px;
  background: #0660e0;
  border-radius: 20px;
  margin-left: 20px;
  top: 15px;
  display: flex;
  align-items: center;
}
.stars {
  width: 45px;
  height: 45px;

  position: absolute;
  text-align: left;
  left: 0;
  margin-left: -15px;
}
.rsfas {
  color: #fff;
  z-index: 999;
  margin-left: 40%;
  font-size: 22px;
}
.room-title {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #197ce4;
  font-size: 19px;
  font-weight: 500;
  color: #fff;
  padding: 0 20px;
  justify-content: space-around;
}
.room-title > div {
  width: 45%;
  height: 55px;

  line-height: 55px;
}
.this_xz {
  border-bottom: 2px solid #fff;
}
.room-box {
  height: calc(100% - 55px - 40px);
  border-bottom: 2px solid #197ce4;
  overflow: auto;
}
.zxrs {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #9ea3a9;
  font-weight: 500;
}
.sxvaeg {
  display: flex;
}
.zwb-box {
  display: flex;

  flex-wrap: wrap;
}
.sdxccas {
  width: 45%;
  height: 60px;
  display: flex;
  justify-content: center;
  margin: 15px 6px;
  color: #fff;
}
.zwlbiasf {
  width: 50px;
  height: 50px;
  background: red;
  border-radius: 50%;
}
.room-box::-webkit-scrollbar {
  width: 4px; /* 滚动条宽度 */
}
.room-box::-webkit-scrollbar-track {
  background: #005ab9; /* 滚动条轨道颜色 */
}
.room-box::-webkit-scrollbar-thumb {
  background: #197ce4; /* 滚动条手柄颜色 */
}
.messred {
  position: absolute;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 10px;
  top: 0;
  right: 0;
  margin-top: 20%;
  margin-right: 10%;
}
.username {
  font-size: 19px;
  color: #fff;
}
.liaotianbox {
  width: 95%;
  margin: auto;
  margin-top: 15px;
}
.txtbox {
  width: 100%;
  background: #1177e3;

  color: #fff;
  font-size: 16px;
  text-align: left;
  padding: 15px 20px;
}
.rtxt {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ltxt {
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.llt-box {
  position: relative;
  height: 95%;
}
.tsc-txt {
  position: absolute;
  width: 70%;
  height: 25px;
  line-height: 25px;
  background: red;
  color: #fff;
  display: flex;
  align-items: center;
}
.zsA-txt {
  position: fixed;
  width: 200px;
  height: 25px;
  bottom: 0;
  margin-bottom: 6%;
  line-height: 25px;
  background: red;
  color: #fff;
  border-radius: 20px;
}

.namebox {
  color: #e4e006;
  margin: 0 5px;
}
/* .triangle {
    position: absolute;
    right: 0;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-right: 12px solid #197ce4;
  border-left: 12px solid transparent;
  border-bottom: 12px solid transparent;

} */
.kk-txt {
  font-size: 22px;
  margin: 5px 0;
  text-align-last: left;
}
.article {
  padding: 15px;
}
.home-integral2 {
  display: flex;
  align-items: center;

  height: 45px;
  border-radius: 20px;
  background: #1a78e1;
  margin-left: 50px;
  padding: 0 10px;
}
.home-integral2 > img {
  width: 35px;
  height: 30px;
}
/deep/ .adfjlable {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0 !important;
}
.saug {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 3px 0;
}

/deep/ .el-form-item__content {
  text-align: left;
}
/* .el-radio__input.is-checked + .el-radio__label {
  color: v-bind('dynamicStyle' ) !important; 
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: v-bind('dynamicStyle' ) !important;
  background: v-bind('dynamicStyle' ) !important;
} */

/deep/ .el-input__inner {
  width: 100% !important;
  height: 80px !important;
  line-height: 80px !important;
  font-size: 30px !important;
  background: #fff !important;
  border: 1px solid #ccc;

  color: #000;
  text-align: center;
}
/deep/.el-radio__inner {
  width: 20px;
  height: 20px;
}
.fasfxf {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-content: space-between;
}
.tixjstb {
  width: 200px;
  height: 50px;
  font-size: 19px !important;
  border-radius: 23px !important;
  margin: 0 50px;
  background: #42b983 !important;
}
.xsdcshow {
  width: 200px;
  height: 50px;
  font-size: 19px !important;
  border-radius: 23px !important;
  margin: 0 50px;
}
.el-dialog {
  margin: auto;
}
.lxtlog > .el-dialog {
  height: 400px;
}
/deep/ .el-form-item__label {
  width: 100%;
  text-align: left !important;
  line-height: 40px !important;
  margin-bottom: 10px;
}
.el-form-item {
  margin-bottom: 18px;
}
/deep/ .el-dialog__body {
  padding: 15px 20px !important;
}

.xxbox {
  position: fixed; /* 定位方式 */
  top: 0; /* 顶部对齐 */
  left: 0; /* 左边对齐 */
  width: 100%; /* 宽度为100% */
  height: 100%; /* 高度为100% */
  background: rgba(30, 55, 58, 0.5); /* 半透明背景 */
  z-index: 1000; /* 高的z-index值确保遮罩层在其他内容之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.asfjx {
  position: relative;
  width: 400px;
  height: 390px;
  background: #989ea5;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}
.lajjs {
  top: 0;
  left: 0;
  position: absolute;
  margin-top: -50px;
  margin-left: -20px;
}
.rajjs {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -50px;
  margin-right: -20px;
}
.sdksnjg {
  width: 360px;
  height: 300px;
  background: #fff;
  z-index: 9999;
  border-radius: 40px;
  position: relative;
  display: flex;
  justify-content: center;
}
.jsxfd {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  margin-top: -150px;
  background: url(../assets/xiaobangyy/pop/logo.png);
  display: flex;
  justify-content: center;
  align-items: center;
}
.vkah {
  font-size: 25px;
  color: red;
  margin-top: 80px;
}
.aksuzjxf {
  width: 85%;
  height: 70%;
  margin: auto;
  padding-top: 90px;
  font-size: 18px;
  text-align: left;
}
.afhnf,
.ksasgd {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.afhnf {
  position: relative;
  justify-content: center;
}
.btntxt {
  position: absolute;
  font-size: 17px;
  color: #fff;
  text-align: center;
  font-weight: 700;
}
.saghdfsfas {
  width: 100%;
  position: relative;
  font-size: 15px;
  text-align: center;
}
.correct-answer {
  color: #409eff; /* 或者你想要的正确答案样式 */
}
.fahsgd {
  display: flex;
  text-align: left;
}
.zjlxdba {
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-top: 40px;
}
.adshgfjg {
  width: 200px;
  height: 40px;
}

.word-container {
  display: inline-block;
  font-size: 24px;
  transition: font-size 1s ease-in-out;
}

.word {
  display: inline-block;
}

.letter {
  display: inline-block;
}

.full-word {
  animation: grow 1s ease-in-out forwards;
}

@keyframes grow {
  0% {
    font-size: 24px;
  }
  100% {
    font-size: 35px;
  }
}

.jfgdfh {
  margin: 0 5px;
}
.csjdafaf {
  margin-top: 15px;
}
/deep/.el-form-item__label {
  font-size: 30px !important;
  margin-bottom: 25px !important;
}
.fakxb-fgcfg {
  font-size: 25px;
}
.xytbsfn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 35px;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  right: 0;
  margin-right: 5%;
  background: #42b983 !important;
}
.czfs-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}
.xinxiang {
  width: 40px;
  height: 40px;
  z-index: 999;
}

* {
  margin: 0;
  padding: 0;
}
.box {
  margin-left: -12px;
  box-sizing: border-box;
  position: relative;
}
.wifi-symbol {
  width: 45px;
  height: 45px;
  box-sizing: border-box;
  overflow: hidden;
  transform: rotate(135deg);
}
.wifi-circle {
  border: 2.5px solid #999999;
  border-radius: 50%;
  position: absolute;
}

.first {
  width: 5px;
  height: 5px;
  background: #cccccc;
  top: 45px;
  left: 45px;
}

.second {
  width: 25px;
  height: 25px;
  top: 35px;
  left: 35px;
}

.third {
  width: 40px;
  height: 40px;
  top: 28px;
  left: 28px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0; /*初始状态 透明度为0*/
  }
  100% {
    opacity: 1; /*结尾状态 透明度为1*/
  }
}

.animate .second {
  animation: fadeInOut 1s infinite 0.2s;
}

.animate .third {
  animation: fadeInOut 1s infinite 0.4s;
}

.yyimg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.khcxcy {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #42b983;
}
.njkdgfgd {
  width: 60px;
  height: 60px;
}
.njkdgfgd2 {
  width: 60px;
  height: 55px;
}
.nextTO {
  padding: 10px 30px;
  position: absolute;
  top: 0;
  right: 0;
}
.xxsvad {
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 30px;
  position: absolute;
  top: 0;
  right: 0;
}
.gjasfgeg {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #67c23a;
}
.startly {
  width: 40px;
  height: 40px;
}
.sfdgmhrgj {
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.clisdjfd {
  color: #ccc;
  margin: 15px 0;
}
.xxxyx {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 20px;
  margin-top: 20px;
  padding: 10px 20px;
}

</style>
