import request from '../utils/request'

export function userLogin(data) {
    return request({
        url: '/user/login',
        method: 'post',
        data
      })
}

export function getUserInfo(data) {
  return request({
      url: '/user/getUserInfo',
      method: 'post',
      data
    })
}
// 获取砖石
export function jewelMy(data) {
  return request({
      url: '/jewel/my',
      method: 'post',
      data
    })
}
// 获取砖石列表
export function jewelLsit(data) {
  return request({
      url: '/jewel/list',
      method: 'post',
      data
    })
}
//钻石商城
export function jewelmallLsit(data) {
  return request({
      url: '/jewelmall/list',
      method: 'post',
      data
    })
}


// 获取所有学习课程
export function studycentreCourses(data) {
  return request({
      url: '/studycentre/courses',
      method: 'post',
      data
    })
}


// 学习轨迹
export function learningpath(data) {
  return request({
      url: '/studycentre/learningpath',
      method: 'post',
      data
    })
}


// 获取全部AI老师
export function aiteacherList(data) {
  return request({
      url: '/aiteacher/list',
      method: 'get',
      data
    })
}

// 获取我的AI老师
export function aiteacherGet(data) {
  return request({
      url: '/aiteacher/get',
      method: 'post',
      data
    })
}


// 设置AI老师
export function aiteacherSet(data) {
  return request({
      url: '/aiteacher/set',
      method: 'post',
      data
    })
}


// 获取课程内容
export function studycentreCourseinfo(data) {
  return request({
      url: '/studycentre/courseinfo',
      method: 'post',
      data
    })
}


// 文本转语音
export function readAloud(data) {
  return request({
      url: '/ai/t2a',
      method: 'post',
      data
    })
}

// AI对话 SSE
export function aichatsse(query) {
  return request({
      url: '/aichat/sse',
      method: 'get',
      params: query
    })
}


// 获取课程列表
export function aicourse(data) {
  return request({
      url: '/aicourse/index',
      method: 'post',
      data
    })
}

// 获取课程详情
export function particulars(data) {
  return request({
      url: '/aicourse/info',
      method: 'post',
      data
    })
}

// 语音查询页数【课程详情】
export function likeinfo(data) {
  return request({
      url: '/aicourse/likeinfo',
      method: 'post',
      data
    })
}


// AI对话【非SSE】
export function aichatChat(data) {
  return request({
      url: '/aichat/chat',
      method: 'post',
      data
    })
}




// 检查是否学习完成
export function checkstudycompleted(data) {
  return request({
      url: '/aicourse/checkstudycompleted',
      method: 'post',
      data
    })
}



// 添加学习记录
export function addstudylog(data) {
  return request({
      url: '/aicourse/addstudylog',
      method: 'post',
      data
    })
}

// v上次学习
export function laststudy(data) {
  return request({
      url: '/aicourse/laststudy',
      method: 'post',
      data
    })
}


// 重置学习日志
export function resetstudy(data) {
  return request({
      url: '/aicourse/resetstudy',
      method: 'post',
      data
    })
}


export function getcourseusers(data) {
  return request({
      url: '/aicourse/getcourseusers',
      method: 'post',
      data
    })
}



// 获取下一节课ID
export function getnextcourseid(data) {
  return request({
      url: '/aicourse/getnextcourseid',
      method: 'post',
      data
    })
}

// 单词拼读
export function wordspell(data) {
  return request({
      url: '/aicourse/wordspell',
      method: 'post',
      data
    })
}


// 全部年级
export function grade(data) {
  return request({
      url: '/subject/grade',
      method: 'GET',
      data
    })
}

// 全部科目
export function subject(data) {
  return request({
      url: '/subject/subject',
      method: 'GET',
      data
    })
}

// 全部科目
export function quicksearch(data) {
  return request({
      url: '/subject/quicksearch',
      method: 'POST',
      data
    })
}

// 查询课程页
export function subjectcontent(data) {
  return request({
      url: '/aicourse/subjectcontent',
      method: 'POST',
      data
    })
}

















